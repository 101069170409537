import React from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import { useSelector, useDispatch } from 'react-redux';

import '../../../styles/CodingTestPage/CodingConsolePane.css';

import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import "codemirror/mode/shell/shell";
import "codemirror/theme/the-matrix.css";

import { RESULT_TO_PASS_STATUS, StatusToIcon } from "../../../runResultTypesMapping.js";
import CircularProgress from '@material-ui/core/CircularProgress';

const codeMirrorOptions = {
    mode: "Shell",
    readonly: true,
    theme: "the-matrix",
    lineWrapping: true,
    styleSelectedText: true,
};


/*
TestCases will be an array of objects of the following form

{
sample: true, //true or false
name: "Sample Test Case 1",
pass_status: "", //pass, fail, timeout, OOM, error
input: "",  // this should not be served by the API if it is not a sample test case
output: "", // this should not be served by the API if it is not a sample test case
*/


function UpdateSampleTestCaseSelection(SampleTestCaseIndex) {
    return ((dispatch) => {
        const action = {
            type: "CODE/SAMPLE_TEST_CASE_SELECTION",
            payload: SampleTestCaseIndex,
        };

        dispatch(action);
    });

}

export function UpdateSubmitTerminalSelection(index) {
    return ((dispatch) => {
        const action = {
            type: "CODE/RESULTS_SECTION_TAB_SELECTION",
            payload: index,
        };

        dispatch(action);
    });

}


//JSX returning functions

function DisplayInputOutput(TestCase, actualOutput) {
    return (
        <div
            style={{ display: "inline-block", "whiteSpace": "nowrap", width: "100%", height: "100%" }}>
            <div
                style={{ display: "inline-block", "whiteSpace": "nowrap", width: "50%", height: "100%" }}
            >
                <span style={{ marginLeft: "10px" }}>Input:</span>
                <Card style={{ margin: "10px", marginRight: "0px", height: "calc(50% - 1rem - 26.5px)", background: "#ebebeb", wordWrap: "break-word", overflowY: "auto" }}
                    variant="outlined">
                    <CardContent style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                        {TestCase.input}
                    </CardContent>
                </Card>
                <span style={{ marginLeft: "10px" }}>Expected Output:</span>
                <Card style={{ margin: "10px", marginRight: "0px", height: "calc(50% - 1rem - 26.5px)", background: "#ebebeb", overflowY: "auto" }}
                    variant="outlined">
                    <CardContent style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                        {TestCase.output}
                    </CardContent>
                </Card>
            </div>
            <div
                style={{ display: "inline-block", "whiteSpace": "nowrap", width: "50%", height: "100%" }}
            >
                <span style={{ marginLeft: "10px" }}>Your Console Output:</span>
                <Card style={{ margin: "10px", height: "calc(100% - 1rem - 25px)", background: "#ebebeb", overflowY: "auto" }}
                    variant="outlined">
                    <CardContent style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                        {actualOutput}
                    </CardContent>
                </Card>
            </div>
        </div>
    );

}

/*
function DisplayActualOutput (output){
    return(
        <Card style= {{ margin: "10px", display:"inline-block" }}>
            <CardContent>
                {output}
            </CardContent>
        </Card>
    );

}
*/

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <div
                    style={{ width: "100%", height: "100%" }}>
                    {children}
                </div>
            )}
        </div>
    );

}


function SampleTestCasesToJSX(TestCases, TestCaseSelection, Results) {
    /*
    Results must be an array of object with a pass_status and output elements. 
    TestCaseSelection must be an integer
    TestCases must be an array of objevts with input, output elements
    */
    while (TestCases.length > Results.length){
        Results.push(Results.slice(-1).pop());
    }
    const TabJSX = TestCases.map((TestCase, index) => {
        const pass_status = Results[index].pass_status// Index out of bounds?

        const colors = {};
        colors[RESULT_TO_PASS_STATUS.PASS] = "green";
        colors[RESULT_TO_PASS_STATUS.FAIL] = "red";
        colors[RESULT_TO_PASS_STATUS.CERROR] = "red";
        colors[RESULT_TO_PASS_STATUS.RERROR] = "red";

        return (
            <Tab
                label={<span style={{ color: colors[pass_status] }}>{TestCase.name}</span>}
                icon={<span style={{ color: colors[pass_status] }}>{StatusToIcon(pass_status)}</span>}
            />
        );
    });
    //Check whther you need to add keys

    const TabPanelJSX = TestCases.map((TestCase, index) => {
        const actual_output = Results[index].output; //index out of bounds?
        return (
            <TabPanel
                value={TestCaseSelection}
                index={index}
                key={index} //check if this is alright
                style={{ width: "calc(100% - 10rem)", height: "100%" }}
            >
                <div
                    style={{ display: "inline-block", "whiteSpace": "nowrap", width: "100%", height: "100%" }}
                >
                    {DisplayInputOutput(TestCase, actual_output)}
                </div>
            </TabPanel>
        );
    });

    const dispatch = useDispatch();

    return (
        <div style={{
            display: "flex",
            flexGrow: 1,
            height: "350px"
        }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={TestCaseSelection}
                style={{ overflow: "visible", width: "10rem" }}
                onChange={(event, newValue) => {
                    dispatch(UpdateSampleTestCaseSelection(newValue));
                }}
            >
                {TabJSX}
            </Tabs>
            {TabPanelJSX}
        </div>
    );


}

function TerminalPane() {

    const QID = useSelector(state => state.Code.Contest["CurrentQuestionID"]);

    const output = useSelector(
        state => state.Code.Contest[QID].TerminalOutput
    );

    const loading = useSelector(
        state => state.Code.CodeUI.CustomRunLoading
    );


    return (
        <div>
            {loading ? (
                <div style={{height: "370px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress/>
                </div>
            ) : (
                <Card style={{ margin: "10px" }}
                    className='console'>
                    <CodeMirror
                        value={output}
                        options={codeMirrorOptions}
                    />
                </Card>
            )}
        </div>

    );
}


function SubmitResultsPane() {

    const QID = useSelector(state => state.Code.Contest["CurrentQuestionID"]);

    var SubmitResults = useSelector(
        state => state.Code.Contest[QID].SubmitResults
    );
    const SampleResults = useSelector(
        state => state.Code.Contest[QID].SampleResults
    );
    while (SampleResults.length > SubmitResults.length){
        SubmitResults.push(SubmitResults.slice(-1).pop());
    }

    const loading = useSelector(
        state => state.Code.CodeUI.SubmitLoading
    );
        
    const SubmitResultsJSX = SubmitResults.map((result, index) => {
        const colors = {};
        colors[RESULT_TO_PASS_STATUS.PASS] = "green";
        colors[RESULT_TO_PASS_STATUS.FAIL] = "red";
        colors[RESULT_TO_PASS_STATUS.CERROR] = "red";
        colors[RESULT_TO_PASS_STATUS.RERROR] = "red";
        return (
            <span
                style={{
                    textAlign: "center",
                    width: "170px",
                    height: "80px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "inline-block",
                    color: colors[result],
                }}
            >
                TEST CASE {index}
                <br></br>
                {StatusToIcon(result)}
                <br></br>
                {result}
            </span>
        );
    });
    if(loading){
        return (<div style={{height: "370px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <CircularProgress/>
        </div>)
    }else{
        return SubmitResultsJSX;
    }

}

function SampleTestsPane() {

    const QID = useSelector(state => state.Code.Contest["CurrentQuestionID"]);

    const SampleTestCases = useSelector(
        state => state.Code.Contest[QID].SampleTestCases
    );
    const SampleTestCaseSelection = useSelector(
        state => state.Code.CodeUI.SampleTestCaseSelection
    );
    const SampleResults = useSelector(
        state => state.Code.Contest[QID].SampleResults
    );

    const loading = useSelector(
        state => state.Code.CodeUI.RunLoading
    );

    return (
        <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            {loading ? (
                <div style={{height: "350px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress/>
                </div>
            ) : (
                SampleTestCasesToJSX(SampleTestCases, SampleTestCaseSelection, SampleResults)
            )}
        </div>
    );

}

export function ResultsPane() {
    //choose between putting card outside or inside

    const SubmitTerminalSelection = useSelector(
        state => state.Code.CodeUI.ResultsSectionTabSelection
    );

    const dispatch = useDispatch();
    return (
        <div >
            <Card style={{ marginBottom: "10px" }}>

                <Tabs
                    value={SubmitTerminalSelection}
                    onChange={(event, newValue) => {
                        dispatch(UpdateSubmitTerminalSelection(newValue));
                    }}
                >
                    <Tab label="Sample Tests" />
                    <Tab label="Results" />
                    <Tab label="Terminal" />
                </Tabs>


                <TabPanel value={SubmitTerminalSelection} index={0}>
                    <SampleTestsPane />
                </TabPanel>
                <TabPanel value={SubmitTerminalSelection} index={1}>
                    <SubmitResultsPane />
                </TabPanel>
                <TabPanel value={SubmitTerminalSelection} index={2}>
                    <TerminalPane />
                </TabPanel>

            </Card>
        </div>
    );
}


//-----------------------------------------------------------------------------------\
//Exporting some utilities
export const RESULTS_PANE_TABS = {
    SAMPLE_TESTS_PANE: 0,
    SUBMIT_RESULTS_PANE: 1,
    TERMINAL_PANE: 2,
};
