import React, { useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import axios from "axios";
import PropTypes from "prop-types";

import "codemirror/lib/codemirror.css";
import "codemirror/addon/selection/mark-selection";
import "codemirror/addon/hint/show-hint";

import "../../styles/CodingTestPage/CodingConsolePane.css";

import { ResultsPane } from "./CodingConsole/ResultsPane.js";
import { CodeEditorPane } from "./CodingConsole/CodeEditor.js";
import { SubmitPane } from "./CodingConsole/SubmitPane.js";

export default function CodingConsolePane(props) {
	return (
		<div
			className="code-editor"
			style={{
				overflowY: "scroll",
				height: "calc(100% - 10px)",
				margin: "10px",
				marginBottom: "0px",
				marginRight: "0",
			}}
		>
			<CodeEditorPane questionType = {props.questionType} />
			<SubmitPane questionType = {props.questionType} />
			<ResultsPane />
		</div>
	);
}
