import React, { useDebugValue, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you
import { Form, Container, Row, Col, Image, Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Card, CardContent, CardHeader } from "@material-ui/core";

import "../../styles/AptitudeTestPage/AptitudeChoicesPane.css";
import { connect, useSelector } from "react-redux";
import { postMcqTestEvent } from "../../actions/AptitudeTest";

function AptitudeChoicesPane({ postMcqTestEvent }) {
	const [selectedChoice, setSelectedChoice] = useState(null);
	const [selectedMultiChoice, setSelectedMultiChoice] = useState({});

	const handleChange = (e) => {
		setSelectedChoice(e.target.value);
	};

	const [alertOpen, setAlertOpen] = useState(false);
	const alertTitle = "No option selected";
	const alertDescription = "Click on skip if you don't want to answer the question";

	const [SubmitPressed, UpdateSubmitPressed] = useState(false);
	const [DisableSubmit, UpdateDisableSubmit] = useState(false);
	const [SkipPressed, UpdateSkipPressed] = useState(false);
	const [DisableSkip, UpdateDisableSkip] = useState(false);
        
        const checkSelectedMultiChoice = () => {
            let anySelected = false;
            for(const choice in selectedMultiChoice){
                anySelected = anySelected || selectedMultiChoice[choice];
            }
            return anySelected;
        };
        
	const submitAnswerMcqTest = async (e) => {
		e.preventDefault();
		if (!checkSelectedMultiChoice()) {
			setAlertOpen(true);
		} else {
                        const selectedChoiceIDs = [];
                        for(const choice in selectedMultiChoice){
                            if(selectedMultiChoice[choice])
                                selectedChoiceIDs.push(choice);
                        }
			const msg = {
				signal: "",
				choice: selectedChoiceIDs,
			};
			UpdateSubmitPressed(true);
			UpdateDisableSubmit(true);
			UpdateDisableSkip(true);
			await postMcqTestEvent(msg);
			UpdateSubmitPressed(false);
			UpdateDisableSubmit(false);
			UpdateDisableSkip(false);
			setSelectedChoice(null);
		}
	};

	const [skipQuestionAlertOpen, setSkipQuestionAlertOpen] = useState(false);
	const skipQuestionAlertTitle = "Are you sure?";
	const skipQuestionAlertDescription = "You will not get another question to replace this one";

	const skipQuestion = async () => {
		const msg = {
			signal: "",
			choice: [],
		};
		UpdateSkipPressed(true);
		UpdateDisableSubmit(true);
		UpdateDisableSkip(true);
		await postMcqTestEvent(msg);
		UpdateSkipPressed(false);
		UpdateDisableSubmit(false);
		UpdateDisableSkip(false);
		setSelectedChoice(null);
	};

	let choices = useSelector((state) => state.mcq.question.choices);
    
    useEffect( () =>{
        
        setSelectedMultiChoice({});

        choices.forEach((x,i) => {
            setSelectedMultiChoice( current => { return {...current , [x.id]:false}; } ); //setting initial state
        });


    },[choices]);
	
    const JSXChoicesMultiCorrectList = choices.map((value, index) => {
		return (
			<Card
				fluid
				style={{ margin: "1rem" }}
				onClick={ () => {
					setSelectedMultiChoice( current => { 
                                            const temp = {...current};
                                            temp[value.id] = !temp[value.id];
                                            return temp;
                                        });
				}}
			>
				<Form.Check
					type="checkbox"
					id={`check-api-radio`}
					defaultChecked={null}
					style={{ width: "fit-content", marginLeft: "20px", marginBottom: "10px", pointerEvents: "none" }}
				>
					<Form.Check.Label>
						<Row>
							<div className="center">
								<Form.Check.Input
									type="checkbox"
									isValid
									name="choice"
									value={value.id}
									checked={selectedMultiChoice[value.id]}
								/>
								<Row className="align-items-center">
									<Col style={{ marginRight: "3px", marginBottom: "-20px", marginTop: "5px" }}>
										<ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
											{value.choice_text}
										</ReactMarkdown>
									</Col>
									{value.choice_image != null && (
										<Col>
											<Image
												className="option-image"
												src={value.choice_image}
												style={{ maxWidth: "90%", maxHeight: "20rem" }}
											/>
										</Col>
									)}
								</Row>
							</div>
						</Row>
					</Form.Check.Label>
				</Form.Check>
			</Card>
		);
	});

	const JSXcircularspinner = (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<CircularProgress size="1.5rem" />
		</div>
	);

	const JSXSkipButton = SkipPressed ? JSXcircularspinner : "SKIP";
	const JSXSubmitButton = SubmitPressed ? JSXcircularspinner : "SUBMIT";

	return (
		<div style={{ height: "calc(100% - 20px)" }}>
			<Card
				className="left options-pane-surface"
				style={{ overflow: "auto", height: "100%", width: "calc(100% - 20px)", padding: "0px" }}
			>
				<div style={{ height: "100%" }}>
					<Form>{JSXChoicesMultiCorrectList}</Form>
					<br />
					<div style={{ display: "flex", justifyContent: "space-around" }}>
						<div>
							<Button
								style={{ width: "8rem", margin: "10px" }}
								disabled={DisableSkip}
								variant="warning"
								onClick={() => setSkipQuestionAlertOpen(true)}
							>
								{JSXSkipButton}
							</Button>
							<Button
								style={{ width: "8rem", margin: "10px" }}
								disabled={DisableSubmit}
								variant="primary"
								onClick={submitAnswerMcqTest}
							>
								{JSXSubmitButton}
							</Button>
						</div>
					</div>
				</div>
			</Card>
			{/* empty submit alert */}
			<Dialog
				open={alertOpen}
				onClose={() => setAlertOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{alertDescription}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setAlertOpen(false)} color="secondary" autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
			{/* confirm skip question alert */}
			<Dialog
				open={skipQuestionAlertOpen}
				onClose={() => setSkipQuestionAlertOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">{skipQuestionAlertTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{skipQuestionAlertDescription}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setSkipQuestionAlertOpen(false)} color="secondary" autoFocus>
						CANCEL
					</Button>
					<Button
						onClick={() => {
							skipQuestion();
							setSkipQuestionAlertOpen(false);
						}}
						color="primary"
						autoFocus
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default connect(null, { postMcqTestEvent })(AptitudeChoicesPane);
