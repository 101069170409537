import React from "react";
import NavBar from "../components/NavBar";

function Page404() {
	return (
		<div>
			<NavBar />
			<h1>404 NOT FOUND</h1>
			<p>Requested resource was not found</p>
		</div>
	);
}

export default Page404;
