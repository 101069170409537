import axios from "axios";
import { ANNOUNCEMENT } from "./types";

export const get_announcements = () => async (dispatch) => {
	if (localStorage.getItem("token")) {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
				Accept: "application/json",
			},
		};

		try {
            dispatch({type : ANNOUNCEMENT.GET_ANNOUNCEMENT_REQUEST});
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/announcement/list/?format=json`,config);
			
            dispatch({
                type : ANNOUNCEMENT.GET_ANNOUNCEMENT_SUCCESS,
                payload :res.data
            })
		} catch (err) {
			dispatch({ 
                type: ANNOUNCEMENT.GET_ANNOUNCEMENT_FAIL,
				payload : err.message
            });
		}
	} else {
		dispatch({ type: "NUKE_STORE" });
		// logout and nuke store
	}
}
