import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, withRouter, useHistory } from "react-router-dom";
import axios from "axios";
import { Form, Button, Container, Jumbotron } from "react-bootstrap";
import NavBar from "../components/NavBar";

async function update_password(formData) {
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Token ${localStorage.getItem("token")}`,
			Accept: "application/json",
		},
	};

	let body = {};
	let setPassword = formData.setPassword;
	if (setPassword === formData.confirmPassword) {
		if (setPassword.length >= 8) {
			if (!/\d/.test(setPassword)) {
				return "your password should contain atleast one numeric";
			}
		} else {
			return "your password needs to be atleast 8 characters long including atleast one numeric";
		}
	} else {
		return "The passwords you entered do not match. Please try again";
	}

	body = JSON.stringify({ password: setPassword });

	try {
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/change_password/`, body, config);
		if (res.data === "password has been updated!!") {
			return res.data;
		} else {
			// some other message regarding password formatting
			return res.data;
		}
	} catch (err) {
		return "Something went wrong please try again";
	}
}

function UpdatePasswordPage() {
	let history = useHistory();

	const initialFormData = Object.freeze({
		setPassword: "",
		confirmPassword: "",
	});
	const [formData, setFormData] = useState(initialFormData);

	const initialHelperTexts = Object.freeze({
		setPassword: "",
		confirmPassword: "",
	});
	const [helperTexts, setHelperTexts] = useState(initialHelperTexts);

	const validateSetPassword = (e) => {
		let setPassword = e.target.value.trim();
		setFormData({ ...formData, [e.target.id]: setPassword });

		if (setPassword.length < 8) {
			setHelperTexts({
				...helperTexts,
				setPassword: "Your password should have atleast 8 characters, including one numeric",
			});
		} else if (!/\d/.test(setPassword)) {
			setHelperTexts({
				...helperTexts,
				setPassword: "Your password should contain atleast one numeric",
			});
		} else {
			setHelperTexts({
				...helperTexts,
				setPassword: "Good Password",
			});
		}
	};

	const validateConfirmPassword = (e) => {
		let confirmPassword = e.target.value.trim();
		setFormData({ ...formData, [e.target.id]: confirmPassword });

		if (formData["setPassword"] === confirmPassword) {
			setHelperTexts({
				...helperTexts,
				confirmPassword: "Passwords match",
			});
		} else {
			setHelperTexts({
				...helperTexts,
				confirmPassword: "passwords do not match",
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// action to send password, invalidate UID and token from backend
		let resp = update_password(formData);
		resp.then((a) => {
			console.log(a);
			if (a === "password has been updated!!") {
				history.push("/home");
				alert(a);
			} else {
				// display error from update_password function
				alert(a);
			}
		});
	};

	return (
		<div>
			<NavBar />
			<br />
			<br />
			<br />

			<Container>
				<Jumbotron>
					<h2>CHANGE LOGIN PASSWORD</h2>
					<div>
						<Form>
							<Form.Group controlId="setPassword">
								<Form.Label>Enter new password</Form.Label>
								<Form.Control
									type="password"
									placeholder="Enter password"
									onChange={validateSetPassword}
								/>
								<Form.Text className="text-muted">{helperTexts["setPassword"]}</Form.Text>
							</Form.Group>

							<Form.Group controlId="confirmPassword">
								<Form.Label>Confirm new password</Form.Label>
								<Form.Control
									type="password"
									placeholder="Enter password again"
									onChange={validateConfirmPassword}
								/>
								<Form.Text className="text-muted">{helperTexts["confirmPassword"]}</Form.Text>
							</Form.Group>

							<Button variant="primary" type="submit" onClick={handleSubmit}>
								Submit
							</Button>
						</Form>
					</div>
				</Jumbotron>
			</Container>
		</div>
	);
}

export default withRouter(connect(null, {})(UpdatePasswordPage));
