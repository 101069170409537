import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

// create initialState by using some api on page load
const initialState = {};

const middleware = [thunk];
let store;
if (process.env.NODE_ENV === "production") {
	store = createStore(rootReducer, initialState, applyMiddleware(...middleware));
} else {
	store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
}
const persistor = persistStore(store);

export { store, persistor };
