import React, { useState, useEffect, useRef } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import { useSelector, useDispatch } from 'react-redux';
import { LANGUAGES } from "../../../langlist.js";
import "codemirror/mode/clike/clike"; //for c++ and java
import "codemirror/mode/python/python";// for python


import Card from "@material-ui/core/Card";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";

import "codemirror/theme/ayu-dark.css";
import { Button } from "@material-ui/core";

import Modal from "react-modal";
import { Row } from "react-bootstrap"


const codeMirrorOptions = {
    lineNumbers: true,
    lineWrapping: true,
    indentUnit: 4
    //theme: "the-matrix",
    //lineWrapping: true
    //styleSelectedText: true,
};


function UpdateCodeEditorStoreCode(lang, code) {
    // to update the code written to the redux store for the current language selection
    return (dispatch, getState) => {
        const QID = getState().Code.Contest["CurrentQuestionID"];

        const payload = {
            "QuestionID": QID,
            "Language": lang,
            "Code": code,
        };
        const action = {
            "type": "CODE/UPDATE_CODE",
            "payload": payload,
        };

        dispatch(action);
    };

}

function UpdateCodeEditorStoreLanguage(lang) {
    //to update the current language selection
    return (dispatch, getState) => {
        const QID = getState().Code.Contest["CurrentQuestionID"];
        const payload = {
            "QuestionID": QID,
            "Language": lang,
        }
        const action = {
            "type": "CODE/CHANGE_LANGUAGE",
            "payload": payload,
        };
        dispatch(action);
    };
}

function UpdateCodeEditorStoreTheme(darkTheme) {
    //to update dark theme setting
    return (dispatch) => {
        const action = {
            "type": "CODE/CODEMIRROR_TOGGLE_DARK_THEME",
            "payload": darkTheme,
        };
        dispatch(action);
    };
}

// disable right click in the code editor
const handleRightClick = (event, setIsModalOpen) => {
    event.preventDefault();
    if (setIsModalOpen) {
        setIsModalOpen(true);
    }
    //alert('Right-clicking is disabled in this app.');
};

const handleCtrlC = (event, setIsModalOpen) => {
    if (event.ctrlKey && event.key === 'c') {
        event.preventDefault();
        if (setIsModalOpen) {
            setIsModalOpen(true);
        }
        //alert('Copying text is disabled in this app.');
    }
};


const handleCtrlV = (event, setIsModalOpen) => {

    if (event.ctrlKey && event.key === 'v') {
        event.preventDefault();

        if (setIsModalOpen) {
            setIsModalOpen(true);
        }
        //alert('Pasting text is disabled in this app.');
    }
};


// JSX returning functions

function CodeEditor(props) {

    // user is needed here to determine if the user is a staff or not. If the user is a staff, then the user
    // can copy paste the code in the editor
    const user = useSelector(state => state.auth.user);

    // disable right click, ctrl + c and ctrl + v, if the question is a preview question
    // And if the question is a test question, then disable paste option if user is not staff
    useEffect(() => {
        // if the question is a test question
        if (props.questionType.isTest) {

            // if the user is not staff, then disable the copy paste option
            if (!user.is_staff) {

                document.addEventListener('contextmenu', (event) => handleRightClick(event, props.setIsModalOpen));
                document.addEventListener('keydown', (event) => handleCtrlC(event, props.setIsModalOpen));
                document.addEventListener('keydown', (event) => handleCtrlV(event, props.setIsModalOpen));


            }
        }
    }, []);

    const QID = useSelector(state => state.Code.Contest["CurrentQuestionID"]);

    const lang = useSelector(
        state => state.Code.Contest[QID].Language
    );

    const mime = useSelector(
        state => state.Code.Contest[QID].Mime
    );

    const darkTheme = useSelector(
        state => state.Code.CodeUI.DarkTheme
    );

    const code = useSelector(
        state => state.Code.Contest[QID][lang]
    );

    const dispatch = useDispatch();
    const codeMirrorRef = useRef();

    useEffect(() => {
        pasteSelectedTextInCodeEditorHandler = (selectedText) => {
            const cm = codeMirrorRef.current.editor;

            // Get the current cursor position
            const cursor = cm.getCursor();

            // Insert the desired text at the cursor position
            cm.replaceRange(selectedText, cursor);
        };
    }, [])



    return (
        <CodeMirror
            ref={codeMirrorRef}
            value={code}
            options={{
                ...codeMirrorOptions,
                mode: mime,
                theme: (darkTheme) ? "ayu-dark" : "default",
            }}
            onBeforeChange={
                (editor, data, value) => {
                    dispatch(UpdateCodeEditorStoreCode(lang, value));
                }
            }

        />
    );
}


function LangSelector() {

    const QID = useSelector(state => state.Code.Contest["CurrentQuestionID"]);

    const lang = useSelector(
        state => state.Code.Contest[QID].Language
    );

    const dispatch = useDispatch();

    return (
        <FormControlLabel
            label="Language :"
            labelPlacement="start"
            style={{ margin: "10px" }}
            control={
                <Select
                    value={lang}
                    onChange={(event) => {
                        dispatch(UpdateCodeEditorStoreLanguage(event.target.value));
                    }}
                    style={{ width: "200px" }}
                >
                    {LANGUAGES.map((value) => {
                        return <MenuItem key={value} value={value}>{value == "C++" ? "C/C++" : value}</MenuItem>;
                    })}
                </Select>
            }
        />
    );

}

function DarkThemeSelector() {

    const darkTheme = useSelector(state => state.Code.CodeUI.DarkTheme);

    const dispatch = useDispatch();

    return (
        <FormControlLabel
            label="Dark Theme :"
            labelPlacement="start"
            style={{ margin: "10px" }}
            control={
                <Switch
                    checked={darkTheme}
                    color="primary"
                    onChange={(event) => {
                        dispatch(UpdateCodeEditorStoreTheme(event.target.checked));
                    }}
                    inputProps={{
                        "aria-label": "primary checkbox",
                    }}
                />
            }
        />
    );
}

let pasteSelectedTextInCodeEditorHandler = null;
function CopyPasteSelector() {
    let selectedText = '';
    const copyButtonClickHandler = () => {
        selectedText = window.getSelection().toString();
        console.log('Selected Text:', selectedText);
    };

    const pasteButtonClickHandler = () => {
        if (pasteSelectedTextInCodeEditorHandler)
            pasteSelectedTextInCodeEditorHandler(selectedText);

    }

    return (
        <div>
            <Button
                variant="contained"
                style={{ marginTop: "20px", marginBottom: "20px" }}
                onClick={copyButtonClickHandler}>
                Copy
            </Button>

            <Button
                variant="contained"
                style={{ margin: "20px" }}
                onClick={pasteButtonClickHandler}
                color="primary">
                Paste
            </Button>
        </div>
    )
}

function CopyPasteModal({ isModalOpen, setIsModalOpen }) {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: { zIndex: 1000 }
    };

    function openModal() {
        setIsModalOpen(true);
    }


    function closeModal() {
        setIsModalOpen(false);
    }

    return (


        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="COPYING AND PASTING IS DISABLED!"
            style={customStyles}
        >
            <div class="modal-header">
                <h5 class="modal-title">COPYING AND PASTING IS DISABLED!</h5>
                {/* <button onClick={closeModal}>close</button> */}
            </div>
            <br />

            <div>
                <p>
                    You are not allowed to right click, copy and paste.
                </p>
                <p>
                    Please use the copy and paste buttons above.
                </p>
            </div>
        </Modal>
    )
}


export function CodeEditorPane(props) {

    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <Card>

            <div
                className="editor-header"
                style={{
                    borderBottom: "1px solid #d1d1d1",
                    display: "block",
                    width: "auto",
                }}
            >
                <FormControl
                    variant="outlined"
                    /*className={classes.formControl}// change this */
                    style={{ display: "block", width: "auto" }}
                >
                    <div
                        style={{
                            display: "block",
                            width: "auto",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <LangSelector />
                        <CopyPasteSelector />
                        <DarkThemeSelector />
                    </div>
                </FormControl>
            </div>
            <CodeEditor questionType={props.questionType} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />


            {isModalOpen && <CopyPasteModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
        </Card>

    );

}
