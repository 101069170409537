import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Link, useParams, useHistory } from "react-router-dom";
import { getMcqTestDetails, getTestRunner } from "../actions/AptitudeTest";
import { Button, Jumbotron, Container, Row } from "react-bootstrap";
import ContestHeader from "../components/ContestHeader";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

function AptitudeTestDetailsPage({ getMcqTestDetails, getTestRunner }) {
	const { pk } = useParams();
	let history = useHistory();

	const [isPageLoading, UpdateisPageLoading] = useState(true);
	useEffect(() => {
		let x = getMcqTestDetails(pk);
		x.then((a) => {
			// console.log(a);
			if (String(a) !== pk) {
				alert(a);
				history.push("/home");
			}
			UpdateisPageLoading(false);
		});
	}, []);

	let test_details = useSelector((state) => state.mcq.test_details);

	const start_test = (e) => {
		//	e.preventDefault();
		//	let x = getTestRunner(pk);
		//	x.then((a) => {
		//		console.log("test runner message", a);
		//		if (a === "valid get question") {
		//			history.push("/aptitude");
		//		} else {
		//			alert(a);
		//		}
		//	});

		history.push("/aptitude");
	};

	if (isPageLoading) {
		return (
			<div
				style={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	} else {
		return (
			<div>
				<ContestHeader />
				<br />
				<Container>
					<Jumbotron as={Paper} style={{ paddingTop: "10px", paddingBottom: "10px", margin: "0px" }}>
						<Row className="d-flex justify-content-center">
							<h1>{test_details.test_name}</h1>
						</Row>
						<hr />
						<Row className="justify-content-left" style={{ width: "100%" }}>
							<h5 style={{ width: "100%" }}>
								<ul style={{ width: "calc(100% - 40px)", marginLeft: "40px" }}>
									<li>
										Test Details
										<ul style={{ marginLeft: "45px", fontSize: "large", fontWeight: "normal" }}>
											<li>Test type: {test_details.test_type}</li>
											<li>Test duration: {test_details.max_time}</li>
											<li>No. of questions: {test_details.max_questions}</li>
										</ul>
									</li>
									<li>
										Instructions
										<ul style={{ marginLeft: "45px", fontSize: "large", fontWeight: "normal" }}>
											{test_details.instructions.split("\n").map((value, index) => {
												return <li key={index}>{value}</li>;
											})}
										</ul>
									</li>
								</ul>
							</h5>
						</Row>
						<br />
						<Row className="justify-content-end">
							<Button onClick={start_test}>Click here to start/resume your test</Button>
						</Row>
					</Jumbotron>
				</Container>
			</div>
		);
	}
}

export default connect(null, { getMcqTestDetails, getTestRunner })(AptitudeTestDetailsPage);
