import { USER_PROFILE } from "../actions/types";

const initialState = {
	simple_profile: { dp: "", bio: "", location: "" },
	recruitment_profile: {},
};

export default function user_profile(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case USER_PROFILE.POST_PROFILE_SUCCESS:
			return { ...state, simple_profile: payload };

		case USER_PROFILE.GET_PROFILE_SUCCESS:
			return { ...state, simple_profile: payload };

		case USER_PROFILE.GET_RECRUITMENT_PROFILE_SUCCESS:
			return { ...state, recruitment_profile: payload };

		case USER_PROFILE.POST_RECRUITMENT_PROFILE_SUCCESS:
			return { ...state, recruitment_profile: payload };

		case "NUKE_STORE":
			return initialState;

		default:
			return state;
	}
}
