import {API_ENDPOINTS} from "../../../APIUrls.js";
import {RESULT_TO_PASS_STATUS} from "../../../runResultTypesMapping.js";
import {API_ReportError} from "../../../ReportError.js"
import axios from "axios";

function APIResultStringToPASS_STATUS ( result ) {
/*this fucntion is not necessary. 
If you do not want to check, you can just return th input to this function.
But if you do want to check, You might want to have a switch case statement here...
*/

    return result;
}


async function API_RunCode( data ){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    const axiosConfig = {
        url: API_ENDPOINTS.RUNCODE,
        method: "post",
        data: data,
        headers: headers,
    };
    

    try{
        var response = await axios( axiosConfig ); 
    
    } catch (error) {
        API_ReportError(error);
        var response = {'data':[{'console_output':'THERE WAS AN ERROR IN FETCHING RESULTS'}]}
    }

    //console.log(response.data)

    const RunResults = [];
        
    for (const result of response.data){
        //assuming this is iterated in order
        const temp = {
            "output": result["console_output"],
            "pass_status": APIResultStringToPASS_STATUS( result["result"] ) ,
        };

        RunResults.push(temp);
    }

    return RunResults;

}

async function API_RunCode_CustomInput( data ){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };

    const axiosConfig = {
        url: API_ENDPOINTS.RUNCODE,
        method: "post",
        data: data,
        headers: headers,
    };
    

    try{
        var response = await axios( axiosConfig ); 
        return response.data["console_output"];
    
    } catch (error) {
        API_ReportError(error);
        return "THERE WAS AN ERROR IN FETCHING RESULTS";
    }

        

}

async function API_PreviewSubmitCode(data){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };

    const axiosConfig = {
        url: API_ENDPOINTS.PREVIEWSUBMITCODE,
        method: "post",
        data: data,
        headers: headers,
    };
    
    try{
        var response = await axios(axiosConfig);
    }catch(error){
        API_ReportError(error);
        var response = {'data':["Something went wrong!"]};
    }
    
    const Results = [];
    for (const result of response.data){
        Results.push( APIResultStringToPASS_STATUS( result ) ) ;
    }

    return Results;
}

async function API_SubmitCode(data){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };

    const axiosConfig = {
        url: API_ENDPOINTS.SUBMITCODE,
        method: "post",
        data: data,
        headers: headers,
    };
    
    try{
        var response = await axios(axiosConfig);
    }catch(error){
        API_ReportError(error);
        var response = {'data':["Something went wrong!"]};
    }
    
    const Results = [];
    for (const result of response.data){
        Results.push( APIResultStringToPASS_STATUS( result ) ) ;
    }

    return Results;
}

export { API_SubmitCode, API_RunCode, API_RunCode_CustomInput, API_PreviewSubmitCode};
