import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const initialState = {
    "ContestID": -1,
    //"ContestAttemptStarted": false, //redux version
    "isStarted": false, //API version
    //"ContestAttemptFinished": false, //redux version
    "isFinished":false, //API version
    "name":"",
    "instructions":"",
    "duration": 0,
    "totalQuestions": 0,
    "ContestLive": true,
    "StartTime": "ERROR",
    "EndTime":"ERROR",
    "AttemptTimeRemaining":null,//from Backend, updated only once when page loads.

};

function TempContestInfoReducer( state=initialState, action) {
    
    switch(action.type){
         
        case "CODE/FETCH_CODING_CONTEST_TIMINGS":
            return {
                ...state,
                "StartTime":action.payload["start"],
                "EndTime":action.payload["end"],
            };

        case "CODE/FETCH_CODING_CONTEST_STATE":
            return {
                ...state,
                "ContestLive": action.payload["ContestLive"],
                "isStarted": action.payload["isStarted"],
                "isFinished": action.payload["isFinished"],
                "name": action.payload["name"],
                "instructions": action.payload["instructions"],
                "duration": action.payload["duration"],
                "totalQuestions": action.payload["totalQuestions"],
            };

        case "CODE/SET_CONTEST_ID":
            return {
                ...state,
                "ContestID": action.payload,
            };

        case "CODE/START_CONTEST_ATTEMPT":
            return {
                ...state,
                //"ContestAttemptStarted": true,
            }
        
        case "CODE/FINISH_CONTEST_ATTEMPT":
            return {
                ...state,
                "ContestID": -1,
                "AttemptTimeRemaining":null,
            };

        case "CODE/UPDATE_TIME_REMAINING":
            return {
                ...state,
                "AttemptTimeRemaining":action.payload,
            };

        case "CODE/RESET_CONTEST_INFO":
            return initialState;
         case "NUKE_STORE":
                return initialState;


        default:
            return state;

    }



}

const PersistConfig = {
    key: "ContestInfo",
    storage: storage,
    whitelist: ["ContestID"],
};

const ContestInfoReducer = persistReducer(PersistConfig, TempContestInfoReducer);

export default ContestInfoReducer;
