import React from "react"; 

import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import MemoryOutlinedIcon from '@material-ui/icons/MemoryOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';

const RESULT_TO_PASS_STATUS = {
    PASS: "Right Answer",
    FAIL: "Wrong Answer",
    TIMEOUT: "Time Limit exceeded",
    OOM: "OOM", //Out of Memory
    CERROR: "Compilation Error",
    RERROR: "Runtime Error",
    UNKNOWN: "Unknown"
};

function StatusToIcon (Status){
    switch(Status){
        case RESULT_TO_PASS_STATUS.PASS :
            return (<CheckCircleOutlinedIcon />);
        case RESULT_TO_PASS_STATUS.FAIL:
            return (<CancelOutlinedIcon />); 
        case RESULT_TO_PASS_STATUS.TIMEOUT:
            return (<ScheduleOutlinedIcon />);
        case RESULT_TO_PASS_STATUS.OOM:
            return (<MemoryOutlinedIcon/>); 
        case RESULT_TO_PASS_STATUS.CERROR:
            return (<WarningOutlinedIcon/>);
        case RESULT_TO_PASS_STATUS.RERROR:
            return (<ErrorOutlineOutlinedIcon />);
        default:
            return (<ContactSupportOutlinedIcon />);
    }

}
export { RESULT_TO_PASS_STATUS, StatusToIcon } ;

