import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { load_user_recruitment_profile, update_user_recruitment_profile } from "../../actions/userProfile";
import { Alert, Form, Button, Row, Col, Container } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";


function RecruitmentForm({ load_user_recruitment_profile, update_user_recruitment_profile }) {
    // get existing data and display
    // create new object on post if it doesnt exist, patch if exists

    const [isPageLoading, setisPageLoading] = useState(true);
    //form data
    const [formData, setFormData] = useState({});
    const [formErrorData, setFormErrorData] = useState({});

    const recruitment_user_profile = useSelector((state) => state.user_profile.recruitment_profile);

    useEffect(() => {
        const load = async () => {
            setisPageLoading(true);
            await load_user_recruitment_profile();
            setFormData({ ...recruitment_user_profile });
            setisPageLoading(false);
        };

        load();
    }, []);
    //The following useEffect is necessary!!!
    // If a user only modifies certain values and presses submit,
    // it needs to send the older values of the unmodified fields to the api
    useEffect(() => {
        setFormData({ ...recruitment_user_profile });
    }, [recruitment_user_profile]);

    // loading spinner
    const [SavePressed, UpdateSavePressed] = useState(false);
    const JSXcircularspinner = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress size="1.5rem" />
        </div>
    );
    const JSXSaveButton = SavePressed ? JSXcircularspinner : "Save/Update Details";

    // handling form data
    const handleChange = (e) => {
        let changes = {};
        const val = e.target.value.trim();
        changes[e.target.name] = val;
        setFormData({ ...formData, ...changes });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        UpdateSavePressed(true);
        await update_user_recruitment_profile(formData, formErrorData, setFormErrorData);
        UpdateSavePressed(false);
    };
    
    const IIT_array = [
        ["iitd","IIT Delhi"],
        ["iitm", "IIT Madras"],
        ["iitb", "IIT Bombay"],
        ["iitk", "IIT Kanpur"],
        ["iitkgp", "IIT Kharagpur"],
        ["iitbhu", "IIT BHU"],
        ["iitg", "IIT Guwahati"],
        ["iitr", "IIT Roorkee"],
        ["iith", "IIT Hyderabad"],
        ["iitdh", "IIT Dhanbad"],
        ["iitp", "IIT Patna"],
        ["iitj", "IIT Jodhpur"],
        ["iitbbr", "IIT Bhubaneshwar"],
        ["iitmnd", "IIT Mandi"],
        ["iithyd", "IIT Hyderabad"],
        ["iitrpr", "IIT Ropar"],
        ["iitgnr", "IIT Gandhinagar"],
        ["iitidr", "IIT Indore"],
    ];
    const BITS_array = [
        ["bitsp", "BITS Pilani"],
        ["bitsg", "BITS Goa"],
        ["bitshyd", "BITS Hyderabad"],
    ];
    const Others_array = [
        ["others", "Other"],
    ];


    /* JSX functions */
    const JSXCollegeChoices = function(){
        const College_array = [
            ...BITS_array,
            ...IIT_array,
            ...Others_array,
        ];
        return (
            <Col>
                <Form.Control
                    onChange={handleChange}
                    required={true}
                    as="select"
                    defaultValue={formData.college_name}
                    name="college_name"
                >
                    <option value="">---------</option>
                    {College_array.map((x) => 
                            (<option value={x[0]}>{x[1]}</option>)
                    )}
                </Form.Control>
                <Form.Text>{formErrorData.college_name}</Form.Text>
            </Col>
        );

    };
    const JSXEntranceRank = function (){
        const IIT_array_code = IIT_array.map((x) => x[0]);
        const BITS_array_code = BITS_array.map((x) => x[0]);

        let label, placeholder;
    
        if(!("college_name" in formData)){

            label = "Entrance Rank";
            placeholder = "College Entrance Rank (Optional)";

        }else if(IIT_array_code.includes(formData["college_name"])){
            
            label = "IIT-JEE Rank";
            placeholder = "JEE Advanced General Rank";

        }else if(BITS_array_code.includes(formData["college_name"])){
            
            label = "BITSAT Score";
            placeholder = "BITSAT Score";

        }else{
            
            label = "Entrance Rank";
            placeholder = "College Entrance Rank (Optional)";
            
        }

        return(
            <Form.Group as={Row}>
                <Form.Label column sm={3}>
                    {label}
                </Form.Label>
                <Col>
                    <Form.Control
                        onChange={handleChange}
                        type="number"
                        placeholder={placeholder}
                        value={formData.college_entrance_metric}
                        name="college_entrance_metric"
                    />
                    <Form.Text>{formErrorData.college_entrance_metric}</Form.Text>
                </Col>
            </Form.Group>
        );

    };
    
    /* Returning the final JSX component*/

    if (isPageLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
            </div>
        );
    } else {
        return (
            <Container>
                <Row>
                    <Alert variant="warning">
                        Please fill the details below accurately. This information will be used during the recruitment
                        process. Your data will not be shared outside the JLR group. Please refresh the page once if any
                        details you have already filled are missing.
                    </Alert>
                </Row>
                <Form>
                    <Row className="justify-content-center">
                        <h4>
                            <u>Personal Details</u>
                        </h4>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    First Name
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        placeholder="First Name"
                                        defaultValue={formData.first_name}
                                        name="first_name"
                                    />
                                    <Form.Text>{formErrorData.first_name}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    Last Name
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        placeholder="Last Name"
                                        defaultValue={formData.last_name}
                                        name="last_name"
                                    />
                                    <Form.Text>{formErrorData.last_name}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    Date of Birth
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        type="date"
                                        defaultValue={formData.dateOfBirth}
                                        name="dateOfBirth"
                                    />
                                    <Form.Text>{formErrorData.dateOfBirth}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    Gender
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        as="select"
                                        defaultValue={formData.gender}
                                        name="gender"
                                    >
                                        <option value="">---------</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="others">Other</option>
                                    </Form.Control>
                                    <Form.Text>{formErrorData.gender}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    Phone No.
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        pattern="[0-9]{10}"
                                        maxLength="10"
                                        type="tel"
                                        defaultValue={formData.phone_number}
                                        name="phone_number"
                                    />
                                    <Form.Text>{formErrorData.phone_number}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    Email
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        type="email"
                                        defaultValue={formData.email}
                                        name="email"
                                    />
                                    <Form.Text>{formErrorData.email}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="justify-content-center">
                        <h4>
                            <u>Academic Details</u>
                        </h4>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    College
                                </Form.Label>
                                {JSXCollegeChoices()}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    Department
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        placeholder="B.Tech Department"
                                        defaultValue={formData.department_name}
                                        name="department_name"
                                    />
                                    <Form.Text>{formErrorData.department_name}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    CGPA
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        min="0"
                                        max="10"
                                        step="0.01"
                                        type="number"
                                        placeholder="College CGPA (scale of 10)"
                                        defaultValue={formData.college_cgpa}
                                        name="college_cgpa"
                                    />
                                    <Form.Text>{formErrorData.college_cgpa}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            {JSXEntranceRank()} 
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    NTSE
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        as="select"
                                        defaultValue={formData.NTSE_scholar}
                                        name="NTSE_scholar"
                                    >
                                        <option value="">---------</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </Form.Control>
                                    <Form.Text>{formErrorData.NTSE_scholar}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    KVPY
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        as="select"
                                        defaultValue={formData.KVPY_scholar}
                                        name="KVPY_scholar"
                                    >
                                        <option value="">---------</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </Form.Control>
                                    <Form.Text>{formErrorData.KVPY_scholar}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    X Board
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        placeholder="Class X Board Name"
                                        defaultValue={formData.class_x_board}
                                        name="class_x_board"
                                    />
                                    <Form.Text>{formErrorData.class_x_board}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    X Marks
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        min="0"
                                        max="100"
                                        step="0.01"
                                        type="number"
                                        placeholder="Class X Marks (out of 100)"
                                        defaultValue={formData.class_x_marks}
                                        name="class_x_marks"
                                    />
                                    <Form.Text>{formErrorData.class_x_marks}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    XII Board
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        placeholder="Class XII Board Name"
                                        defaultValue={formData.class_xii_board}
                                        name="class_xii_board"
                                    />
                                    <Form.Text>{formErrorData.class_xii_board}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    XII Marks
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        onChange={handleChange}
                                        required={true}
                                        min="0"
                                        max="100"
                                        step="0.01"
                                        type="number"
                                        placeholder="Class XII Marks (out of 100)"
                                        defaultValue={formData.class_xii_marks}
                                        name="class_xii_marks"
                                    />
                                    <Form.Text>{formErrorData.class_xii_marks}</Form.Text>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Button onClick={handleSubmit} type="submit" disabled={SavePressed}>
                            <Col>{JSXSaveButton}</Col>
                        </Button>
                    </Row>
                </Form>
            </Container>
        );
    }
}

export default connect(null, { load_user_recruitment_profile, update_user_recruitment_profile })(RecruitmentForm);
