const profileContainer = {
	display: "flex",
	flexDirection: "row",
};
const rightContainer = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	width: "70%",
	height: "100%",
	margin: "8px",
};

const leftContainer = {
	width: "30%",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	verticalAlign: "middle",
	// backgroundColor: "red",
};

const dp = {
	display: "flex",
	height: "16vw",
};

const fullName = {
	fontSize: "32px",
};

const bio = {
	marginBottom: "4px",
	fontSize: "20px",
	opacity: "75%",
};

export default { profileContainer, rightContainer, leftContainer, dp, fullName, bio };
