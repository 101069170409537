import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Link, useParams, withRouter, useHistory } from "react-router-dom";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar";
import { validate_uid_token, set_password } from "../actions/auth";

function SetPasswordPage({ validate_uid_token, set_password }) {
	let history = useHistory();
	const { uid, token } = useParams();

	// validate uid and token. redirect if invalid
	let change_password_state = useSelector((state) => state.auth.change_password);
	if (!change_password_state) {
		let validity = validate_uid_token(uid, token);
		validity.then((a) => {
			if (a === "invalid_link") {
				alert("your link is invalid or expired, please try again");
				history.push("/");
			}
		});
	}

	// initialise helper functions for setpassword form if uid, token are valid
	const initialFormData = Object.freeze({
		setPassword: "",
		confirmPassword: "",
	});
	const [formData, setFormData] = useState(initialFormData);

	const initialHelperTexts = Object.freeze({
		setPassword: "",
		confirmPassword: "",
	});
	const [helperTexts, setHelperTexts] = useState(initialHelperTexts);

	const validateSetPassword = (e) => {
		let setPassword = e.target.value.trim();
		setFormData({ ...formData, [e.target.id]: setPassword });

		if (setPassword.length < 8) {
			setHelperTexts({
				...helperTexts,
				setPassword: "Your password should have atleast 8 characters, including one numeric",
			});
		} else if (!/\d/.test(setPassword)) {
			setHelperTexts({
				...helperTexts,
				setPassword: "Your password should contain atleast one numeric",
			});
		} else {
			setHelperTexts({
				...helperTexts,
				setPassword: "Good password",
			});
		}
	};

	const validateConfirmPassword = (e) => {
		let confirmPassword = e.target.value.trim();
		setFormData({ ...formData, [e.target.id]: confirmPassword });

		if (formData["setPassword"] === confirmPassword) {
			setHelperTexts({
				...helperTexts,
				confirmPassword: "Passwords match",
			});
		} else {
			setHelperTexts({
				...helperTexts,
				confirmPassword: "passwords do not match",
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// action to send password, invalidate UID and token from backend
		let resp = set_password(formData);
		resp.then((a) => {
			console.log(a);
			if (a === "password has been updated!!") {
				history.push("/home");
				alert(a);
			} else if (a === "Your link is invalid. Please try again!!") {
				history.push("/");
				alert(a);
			} else if (a !== null) {
				// display password formatting error in alert
				alert(a);
			} else {
				// server error
				history.push("/");
				alert("something seems to have gone wrong, please try again");
			}
		});
	};

	return (
		<div>
			<NavBar />
			<h2>Welcome, set your password here</h2>
			<div>
				<br />
				<br />
				<br />
				<Container>
					<Form>
						<Form.Group controlId="setPassword">
							<Form.Label>Set password</Form.Label>
							<Form.Control type="password" placeholder="Enter password" onChange={validateSetPassword} />
							<Form.Text className="text-muted">{helperTexts["setPassword"]}</Form.Text>
						</Form.Group>

						<Form.Group controlId="confirmPassword">
							<Form.Label>Confirm password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Enter password again"
								onChange={validateConfirmPassword}
							/>
							<Form.Text className="text-muted">{helperTexts["confirmPassword"]}</Form.Text>
						</Form.Group>

						<Button variant="primary" type="submit" onClick={handleSubmit}>
							Submit
						</Button>
					</Form>
				</Container>
			</div>
		</div>
	);
}

export default withRouter(connect(null, { validate_uid_token, set_password })(SetPasswordPage));
