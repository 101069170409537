import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Navbar, Pagination, Nav, Badge } from "react-bootstrap";
import { postMcqTestEvent } from "../../actions/AptitudeTest";
import "../../styles/CodingTestPage/CodingTestBar.css";
import { TEST_MANAGER } from "../../actions/types";

function AptitudeTestBar({ postMcqTestEvent }) {
	let history = useHistory();
	const dispatch = useDispatch();

	let current_exam_pk = useSelector((state) => state.exam.current_exam_manager.exam_details.pk);
	const [alertOpen, setAlertOpen] = useState(false);
	const alertTitle = "Finish Section";
	const alertDescription = "You cannot attempt this section again!";

	const [timeUpAlertOpen, setTimeUpAlertOpen] = useState(false);
	const timeUpAlertTitle = "Time completed";
	const timeUpAlertDescription = "You will now be redirected away from the test";

	const submitMcqTest = async () => {
		const msg = {
			signal: "finish",
			choice: "",
		};
		dispatch({ type: TEST_MANAGER.END_MCQ_TEST });
		await postMcqTestEvent(msg);
		if (current_exam_pk > 0) {
			history.push("/exam/" + String(current_exam_pk));
		} else {
			history.push("/home");
		}
	};

	const max_time = useSelector((state) => state.mcq.test_details.max_time);
	const start_time = useSelector((state) => state.mcq.test_state.start_time);
	let questionNum = useSelector((state) => state.mcq.test_state.question_counter);
	const max_questions = useSelector((state) => state.mcq.test_details.max_questions);

	const [time_left, set_time_left] = useState("  :  :  ");
	const makeTimer = function () {
		let maxTime = max_time.split(":");
		let startTime = Date.parse(start_time) / 1000;
		let endTime = startTime + maxTime[0] * 3600 + maxTime[1] * 60 + maxTime[2];
		// take this time from the internet and not local
		// or
		// backend sends time remaining
		let now = new Date();
		now = Date.parse(now) / 1000;
		let timeLeft = endTime - now;
		// check this redirect
		// replace alert with dialog
		if (timeLeft <= 0) {
			setTimeUpAlertOpen(true);
			return "00:00:00";
		}
		let hours = Math.floor(timeLeft / 3600);
		let minutes = Math.floor(timeLeft / 60) % 60;
		let seconds = Math.floor(timeLeft % 60);

		hours = hours.toString().padStart(2, "0");
		minutes = minutes.toString().padStart(2, "0");
		seconds = seconds.toString().padStart(2, "0");

		return hours + ":" + minutes + ":" + seconds;
	};
	useEffect(() => set_time_left(makeTimer()), [start_time]);
	useEffect(() => {
		const interval = setInterval(() => set_time_left(makeTimer()), 1000);
		return () => clearInterval(interval);
	}, [start_time]);

	return (
		<div>
			<Navbar className="coding-navbar" expand="lg">
				<Nav>{"Question No. " + questionNum + " / " + max_questions }</Nav>
				<Nav style={{ width: "calc(100% - 32px)", position: "absolute", pointerEvents: "none" }}>
					<Card style={{ padding: "5px", backgroundColor: "teal", width: "fit-content", margin: "auto" }}>
						Time left <Badge variant="light">{time_left}</Badge>
					</Card>
				</Nav>

				<Button
					variant="contained"
					style={{ backgroundColor: "red", color: "white" }}
					onClick={() => setAlertOpen(true)}
				>
					FINISH MCQ TEST
				</Button>
			</Navbar>
			{/* dialog on clicking finish button */}
			<Dialog
				open={alertOpen}
				onClose={() => setAlertOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{alertDescription}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setAlertOpen(false)} color="secondary" autoFocus>
						CANCEL
					</Button>
					<Button
						onClick={async () => {
							setAlertOpen(false);
							await submitMcqTest();
						}}
						color="primary"
						autoFocus
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>

			{/* dialog for time up */}
			<Dialog
				open={timeUpAlertOpen}
				onClose={() => submitMcqTest()}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">{timeUpAlertTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{timeUpAlertDescription}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						autoFocus
						onClick={() => {
							submitMcqTest();
						}}
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default connect(null, { postMcqTestEvent })(AptitudeTestBar);
