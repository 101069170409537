import React, { useState } from "react";
import axios from "axios";

import { useEffect } from "react";

import { API_ENDPOINTS } from "../../APIUrls.js";
import { API_FinishContest } from "./APIHelpers.js";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Navbar, Pagination, Nav, Badge } from "react-bootstrap";

import "../../styles/CodingTestPage/CodingTestBar.css";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Paper } from "material-ui";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

/*
function UpdateTimeRemaining(prevTime) {
	return (dispatch, getState) => {
		const time_left_redux = getState().Code.ContestInfo["AttemptTimeRemaining"];
		const action = {
			type: "CODE/UPDATE_TIME_REMAINING",
			payload: time_left_redux - (Date.now() / 1000 - prevTime),
		};
		dispatch(action);
	};
}
*/

function SelectQuestion(QuestionID) {
	return (dispatch) => {
		const action = {
			type: "CODE/SELECT_QUESTION",
			payload: QuestionID,
		};

		dispatch(action);
	};
}

function FinishContest() {
	return async (dispatch, getState) => {
		const action = {
			type: "CODE/FINISH_CONTEST_ATTEMPT",
		};

		dispatch(action);
	};
}

function makeTimer(time_left) {
	if (time_left < 0) {
		return "00:00:00";
	}
	let TimeLeft = Math.floor(time_left);
	let hours = Math.floor(TimeLeft / 3600);
	TimeLeft = TimeLeft % 3600;
	let minutes = Math.floor(TimeLeft / 60);
	TimeLeft = TimeLeft % 60;
	let seconds = Math.floor(TimeLeft);

	hours = hours.toString().padStart(2, "0");
	minutes = minutes.toString().padStart(2, "0");
	seconds = seconds.toString().padStart(2, "0");

	return hours + ":" + minutes + ":" + seconds;
}


export default function CodingPreviewBar() {
	const active_question = useSelector((state) => state.Code.Contest["CurrentQuestionID"]);
	const QuestionList = useSelector((state) => state.Code.Contest["ContestQuestionIDList"]);
	const current_exam_pk = useSelector((state) => state.exam.current_exam_manager.exam_details.pk);

	const dispatch = useDispatch();
	const history = useHistory();
	const [alertOpen, setAlertOpen] = React.useState(false);
	const alertTitle = "Finish Test";
	const alertDescription = "You are about to finish";
	const handleAlertClose = () => {
		setAlertOpen(false);
	};
	let question_nums = [];
	for (let index = 0; index < QuestionList.length; index++) {
		const questionID = QuestionList[index];
		question_nums.push(
			<Pagination.Item
				key={questionID}
				active={questionID === active_question}
				onClick={() => {
					dispatch(SelectQuestion(questionID));
				}}
			>
				{index + 1}
			</Pagination.Item>
		);
	}
	// selecting the first question when the page is loaded.
	useEffect(() => {
		dispatch(SelectQuestion(QuestionList[0]));
	}, []);

	const [timeUpAlertOpen, setTimeUpAlertOpen] = useState(false);
	const timeUpAlertTitle = "Time completed";
	const timeUpAlertDescription = "You will now be redirected away from the test";
	
        const time_left_redux = useSelector((state) => state.Code.ContestInfo["AttemptTimeRemaining"]);


	return (
		<div>
			<Navbar className="coding-navbar" expand="lg">
				<Nav>
					<Pagination className="question-selector">{question_nums}</Pagination>
				</Nav>
				<Nav style={{ width: "calc(100% - 32px)", position: "absolute", pointerEvents: "none" }}>
					<Card style={{ padding: "5px", backgroundColor: "teal", width: "fit-content", margin: "auto" }}>
						Time left <Badge variant="light">{makeTimer(time_left_redux)}</Badge>
					</Card>
				</Nav>

				<Button
					variant="contained"
					style={{ backgroundColor: "red", color: "white" }}
					onClick={
						() => setAlertOpen(true)
						//history.push("/coding/test/details");
					}
				>
					FINISH CODING TEST
				</Button>
			</Navbar>
			<Dialog
				open={alertOpen}
				onClose={() => setAlertOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{alertDescription}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setAlertOpen(false)} color="secondary" autoFocus>
						CANCEL
					</Button>
					<Button
						onClick={() => {
							setAlertOpen(false);
							history.push("/home");
							dispatch(FinishContest());
						}}
						color="primary"
						autoFocus
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={timeUpAlertOpen}
				onClose={() => {
					history.push("/home");
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">{timeUpAlertTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{timeUpAlertDescription}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						autoFocus
						onClick={() => {
					            history.push("/home");
						}}
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
