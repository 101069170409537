import { ANNOUNCEMENT } from '../actions/types'

const initialState = {
announcement_list : []
}

export default function AnnouncementReducer(state = initialState, action) {
	switch (action.type) {
		case ANNOUNCEMENT.GET_ANNOUNCEMENT_REQUEST:
			return {loading : true, announcement_list : []};

		case ANNOUNCEMENT.GET_ANNOUNCEMENT_SUCCESS:
			return {loading : false, announcement_list : action.payload };
		
		case ANNOUNCEMENT.GET_ANNOUNCEMENT_FAIL:
			return {loading : false, error : action.payload};
		
		case "NUKE_STORE":
			return initialState;

		default:
			return state;
	}
}