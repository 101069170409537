import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import { update_exam_runner } from "../actions/exam";
import { postMcqTestEvent } from "../actions/AptitudeTest";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Nav, Navbar, Image, Container, Row, Badge, Alert } from "react-bootstrap";
import Webcam from "react-webcam";

import "../styles/NavBar.css";
import "../styles/CodingTestPage/CodingTestBar.css";
import logo from "./corporate-logo.png";

//This is a quick fix for thunk for Finishing Coding Contest.
//Should move this to a dedicated thunk/actions file
import { API_FinishContest } from "./CodingPreviewAndTestPage/APIHelpers.js";

function ContestHeader({ update_exam_runner, postMcqTestEvent }) {
	let history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const exam_details = useSelector((state) => state.exam.current_exam_manager.exam_details);

	useEffect(() => {
		if (!document.fullscreenElement && exam_details.enforce_full_screen) {
			setOpen(true);
		}
	}, []);
	useEffect(() => {
		document.addEventListener("fullscreenchange", (event) => {
			// document.fullscreenElement will point to the element that
			// is in fullscreen mode if there is one. If there isn't one,
			// the value of the property is null.
			if (!document.fullscreenElement && exam_details.enforce_full_screen) {
				setOpen(true);
			}
		});
	}, []);

	// webcam
	const [webcamAllowed, setWebcamAllowed] = useState(false);

	// monitoring fullscreen
	const [open, setOpen] = useState(false);
	const [messageTitle, setMessageTitle] = useState("Full Screen Mode required");
	const [messageDescription, setMessageDescription] = useState("This window will go into Full Screen Mode now");

	const handleClose = () => {
		let elem = document.documentElement;
		//Comment this line temporarily in development to prevent forcing full screen
		elem.requestFullscreen({ navigationUI: "show" })
			.then(() => {})
			.catch((err) => {
				alert(`An error occurred while trying to switch into full-screen mode: ${err.message} (${err.name})`);
			});
		setOpen(false);
	};
	// monitoring fullscreen

	//monitoring tab change count
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertTitle, setAlertTitle] = useState("Tab Change Detected");
	const tabChanges = useSelector((state) => state.exam.current_exam_manager.exam_runner.tab_change_count);
	const [alertDescription, setAlertDescription] = useState(
		"Changing tabs can affect your results and may lead to disqualification. You have " +
			(exam_details.max_allowed_tab_changes - tabChanges) +
			" warnings remaining."
	);
	const handleAlertClose = () => {
		setAlertOpen(false);
		if (exam_details.max_allowed_tab_changes <= tabChanges) {
			finishExam();
		}
	};
	// User has switched away from the tab (AKA tab is hidden)
	const onBlur = () => {
		// trigger tab changing only when tab_change_allowed is false and not in sections page
		if (!exam_details.tab_change_allowed && location.pathname.split("/")[1] !== "exam") {
			update_exam_runner(exam_details.pk, { signal: "tab_change" });
			setAlertTitle("Tab Change Detected");
			setAlertDescription(
				"Changing tabs can affect your results and may lead to disqualification. You have " +
					(exam_details.max_allowed_tab_changes - tabChanges - 1) +
					" warnings remaining."
			);
			// increase tab count from here
			setAlertOpen(true);
		}
	};
	useEffect(() => {
		window.addEventListener("blur", onBlur);
		// Specify how to clean up after this effect on component dismount:
		return () => {
			window.removeEventListener("blur", onBlur);
		};
	});
	// monitoring tab change count

	// timer
	const [timeOver, setTimeOver] = useState(false);
	const [timeOverAlertTitle, setTimeOverAlertTitle] = useState("Time completed");
	const [timeOverAlertDescription, setTimeOverAlertDescription] = useState(
		"Time to complete this exam has now elapsed. Thank you"
	);
	
        const exam_finished = useSelector((state) => state.exam.current_exam_manager.exam_runner.exam_finished);
        
        useEffect( () => {

            if(exam_finished === true){
                setTimeOverAlertTitle("Exam Finished");
                setTimeOverAlertDescription("You finished this exam and now will be redirected to the home page");
                setTimeOver(true);
            }else{
                setTimeOverAlertTitle("Time completed");
                setTimeOverAlertDescription("Time to complete this exam has now elapsed. Thank you");
                
            }

        },[exam_finished]);

	//This thunk is necessary to finish coding contests. Need to move this to a common actions.js file
	const FinishCodingContest = function () {
		return async (dispatch, getState) => {
			const ContestID = getState().Code.ContestInfo["ContestID"];
			const success = await API_FinishContest(ContestID);
			const action = {
				type: "CODE/FINISH_CONTEST_ATTEMPT",
			};

			dispatch(action);
		};
	};
	const FinishMcqContest = async () => {
		const msg = {
			signal: "finish",
			choice: "",
		};
		await postMcqTestEvent(msg);
		dispatch({ type: "Mcq/END_MCQ_TEST" });
	};

	const finishExam = async () => {
		await update_exam_runner(exam_details.pk, { signal: "exam_finish" });
		// dispatch({ type: "Mcq/END_MCQ_TEST" });
		// need to gracefully end any active test - remove from redux
		setTimeOver(false);
		if (location.pathname.split("/")[1] === "exam") {
			history.push("/home");
		} else {
			history.push("/exam/" + String(exam_details.pk));
		}
		//This dispatch needs to be done after the redirect!!!!
		dispatch(FinishCodingContest());
		FinishMcqContest();
	};
	const max_time = useSelector((state) => state.exam.current_exam_manager.exam_details.exam_total_time);
	const start_time = useSelector((state) => state.exam.current_exam_manager.exam_runner.exam_start_time);
	const [time_left, set_time_left] = useState("  :  :  ");
	const makeTimer = function () {
		if (start_time === undefined) {
			return "Not started";
		}
		let maxTime = max_time.slice(-9, -7) + ":" + max_time.slice(-6, -4) + ":" + max_time.slice(-3, -1);
		maxTime = maxTime.split(":");
		let startTime = Date.parse(start_time) / 1000;
		let endTime = startTime + maxTime[0] * 3600 + maxTime[1] * 60 + maxTime[2];
		let now = new Date();
		now = Date.parse(now) / 1000;
		let timeLeft = endTime - now;
		if (timeLeft <= 0) {
			setTimeOver(true);
			return "00:00:00";
		}
		let hours = Math.floor(timeLeft / 3600);
		let minutes = Math.floor(timeLeft / 60) % 60;
		let seconds = Math.floor(timeLeft % 60);

		hours = hours.toString().padStart(2, "0");
		minutes = minutes.toString().padStart(2, "0");
		seconds = seconds.toString().padStart(2, "0");

		return hours + ":" + minutes + ":" + seconds;
	};
	useEffect(() => set_time_left(makeTimer()), [start_time]);
	useEffect(() => {
		const interval = setInterval(() => set_time_left(makeTimer()), 1000);
		return () => clearInterval(interval);
	}, [start_time]);
	// timer

	const JSXLogoJLR =
		location.pathname.split("/")[1] === "exam" ? (
			<Nav>
				<Navbar.Brand as={Link} to="/home">
					<Container>
						<Row>
							<Image src={logo} className="logo"></Image>
						</Row>
					</Container>
				</Navbar.Brand>
				<Nav>
					<Nav.Link as={Link} to="/home" id="basic-navbar-element">
						Home
					</Nav.Link>
				</Nav>
			</Nav>
		) : (
			<Navbar.Brand>
				<Container>
					<Row>
						<Image src={logo} className="logo"></Image>
					</Row>
				</Container>
			</Navbar.Brand>
		);

	const JSXWebcamAlert = (
		<Row className="mx-auto justify-content-center">
			<Alert variant="danger">Please allow webcam (from the URL bar) to access the test</Alert>
		</Row>
	);

	return (
		<>
			<Navbar className="justify-content-between" bg="dark" expand="lg">
				{JSXLogoJLR}
				<Nav style={{ color: "white", fontSize: "x-large" }}>
					<span style={{ width: "fit-content", margin: "auto" }}>{exam_details.exam_name}</span>
				</Nav>
				<Nav>
					<Card style={{ padding: "5px", backgroundColor: "teal", width: "fit-content", margin: "auto" }}>
						Total time left <Badge variant="light">{time_left}</Badge>
					</Card>
				</Nav>
			</Navbar>
			{webcamAllowed ? <div></div> : JSXWebcamAlert}
			{/* do not show these prompts in sections page */}
			{/* fullscreen prompt dialog */}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">{messageTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{messageDescription}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
			{/* tab change prompt */}
			<Dialog
				open={alertOpen}
				onClose={handleAlertClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{alertDescription}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleAlertClose} color="primary" autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
			{/* exam time completed prompt */}
			<Dialog
				open={timeOver}
				onClose={finishExam}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="alert-dialog-title">{timeOverAlertTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{timeOverAlertDescription}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={finishExam} color="primary" autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
			{/* webcam access */}
			<Webcam
				style={{ display: "none" }}
				onUserMedia={() => {
					setWebcamAllowed(true);
				}}
				onUserMediaError={() => {
					setWebcamAllowed(false);
				}}
			/>
		</>
	);
}

export default connect(null, { update_exam_runner, postMcqTestEvent })(ContestHeader);
