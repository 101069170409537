import React from "react";
import NavBar from "../components/NavBar";

function Page403() {
	return (
		<div>
			<NavBar />
			<h1>403 FORBIDDEN</h1>
			<p>You do not have access to the requested Resource</p>
		</div>
	);
}

export default Page403;
