import { AUTH } from "../actions/types";

const initialState = {
	isAuthenticated: false,
	user: null,
	change_password: false,
	uid: null,
	set_password_token: null,
};

export default function auth(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case "AUTH_SUCCESS":
			return {
				...state,
				isAuthenticated: true,
			};

		case "AUTH_FAIL":
			return {
				...state,
				isAuthenticated: false,
			};

		case "LOGIN_SUCCESS":
			return {
				...state,
				isAuthenticated: true,
				user_pk: payload.pk,
			};

		case "USER_LOADED_SUCCESS":
			return {
				...state,
				user: payload,
			};

		case "USER_LOADED_FAIL":
			return {
				...state,
				user: null,
			};

		case "LOGIN_FAIL":
			return {
				...state,
				isAuthenticated: false,
				user: null,
				user_pk: null,
			};

		case "LOGOUT":
			return initialState;

		case AUTH.SET_PASSWORD_GET_SUCCESS:
			return {
				...state,
				change_password: true,
				uid: payload.uid,
				set_password_token: payload.token,
			};

		case AUTH.SET_PASSWORD_GET_FAIL:
			return {
				...state,
				change_password: false,
				uid: null,
				set_password_token: null,
			};

		case "NUKE_STORE":
			return initialState;

		default:
			return state;
	}
}
