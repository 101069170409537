import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";

import SplitPane from "react-split-pane";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you
import { Form, Container, Row, Col, Image, Button } from "react-bootstrap";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { Navbar, Pagination, Nav, Badge } from "react-bootstrap";
import "../styles/CodingTestPage/CodingTestBar.css";
import "../styles/NavBar.css";
import logo from "../components/corporate-logo.png";

async function getQuestionDetails(pk) {
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Token ${localStorage.getItem("token")}`,
			Accept: "application/json",
		},
	};
	let res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mcq/qn/preview=${pk}`, config);
	return res.data;
}

export default function AptitudeTestPage() {
	let [qn, setQn] = useState(null);
	const { pk } = useParams();
	useEffect(() => {
		const res = getQuestionDetails(pk);
		res.then((a) => {
			console.log(a);
			setQn(a);
		});
	}, []);

	let difficultyMap = {
		level2text: {
			1: "Easy",
			3: "Medium",
			5: "Hard",
		},
		button_class: {
			1: "btn-success",
			3: "btn-warning",
			5: "btn-danger",
		},
	};

	let subjectMap = {
		aptitude: "Aptitude",
		electrical: "Electrical",
		mechanical: "Mechanical",
	};

	function ContestHeader() {
		return (
			<div>
				<Navbar className="coding-navbar" bg="dark" expand="lg">
					<Navbar.Brand>
						<Container>
							<Row>
								<Image src={logo} className="logo"></Image>
							</Row>
						</Container>
					</Navbar.Brand>
					<Nav
						style={{
							width: "calc(100% - 32px)",
							position: "absolute",
							color: "white",
							fontSize: "x-large",
						}}
					>
						<span style={{ width: "fit-content", margin: "auto" }}>Question Preview</span>
					</Nav>
				</Navbar>
			</div>
		);
	}

	function AptitudeTestBar() {
		let button_class = "btn " + difficultyMap.button_class[qn.level];
		console.log(button_class);
		return (
			<div>
				<Navbar className="coding-navbar" expand="lg">
					<Nav>
						{"Question Id:"}
						{qn.pk}
					</Nav>
					<Nav>
						{"Subject: "}
						{subjectMap[qn.subject]}
					</Nav>
					<Nav>
						<button type="button" class={button_class} disabled={true}>
							{"Difficulty: "}
							{difficultyMap.level2text[qn.level]}
						</button>
					</Nav>
					<Nav>
						{" "}
						{"Author: "} {qn.author.email}{" "}
					</Nav>
				</Navbar>
			</div>
		);
	}

	function AptitudeQuestionPane() {
		let questionText = qn.question_text;
		let questionImage = qn.question_image;
		return (
			<div style={{ height: "100%" }}>
				<Card
					style={{
						margin: "10px",
						height: "calc(100% - 20px)",
						overflowY: "auto",
					}}
				>
					<CardContent>
						<ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
							{questionText}
						</ReactMarkdown>
						<br />
						{questionImage != null && (
							<Image
								src={questionImage}
								style={{
									width: "100%",
									maxWidth: "40rem",
									maxHeight: "40rem",
									"object-fit": "contain",
								}}
							/>
						)}
					</CardContent>
				</Card>
			</div>
		);
	}

	function AptitudeChoicesPane() {
		let choices = qn.choices;
		let selectedChoice = 4; // separate call needed to get the correct choice

		const JSXChoicesList = choices.map((value, index) => {
			return (
				<Card
					fluid
					style={
						value.choice_correct
							? { margin: "1rem", backgroundColor: "mediumseagreen" }
							: { margin: "1rem" }
					}
				>
					<Form.Check
						type="checkbox"
						id={`check-api-radio`}
						defaultChecked={null}
						style={{
							width: "fit-content",
							marginLeft: "20px",
							marginBottom: "10px",
							pointerEvents: "none",
						}}
					>
						<Form.Check.Label>
							<Row>
								<div className="center">
									<Form.Check.Input type="checkbox" isValid name="choice" value={value.id} />
									<Row className="align-items-center">
										<Col style={{ marginRight: "3px", marginBottom: "-20px", marginTop: "5px" }}>
											<ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
												{value.choice_text}
											</ReactMarkdown>
										</Col>
										{value.choice_image != null && (
											<Col>
												<Image
													className="option-image"
													src={value.choice_image}
													style={{ maxWidth: "90%", maxHeight: "20rem" }}
												/>
											</Col>
										)}
									</Row>
								</div>
							</Row>
						</Form.Check.Label>
					</Form.Check>
				</Card>
			);
		});

		return (
			<div style={{ height: "calc(100% - 20px)" }}>
				<Card
					className="left options-pane-surface"
					style={{ overflow: "auto", height: "100%", width: "calc(100% - 20px)", padding: "0px" }}
				>
					<div style={{ height: "100%" }}>
						<Form>{JSXChoicesList}</Form>
						<br />
					</div>
				</Card>
			</div>
		);
	}
	if (qn != null) {
		return (
			<div>
				<ContestHeader />

				<AptitudeTestBar />

				<SplitPane
					split="vertical"
					defaultSize="50vw"
					primary="second"
					style={{ display: "flex", height: "calc(100% - 28.5mm)" }}
					allowResize={false}
				>
					<AptitudeQuestionPane />
					<AptitudeChoicesPane />
				</SplitPane>
			</div>
		);
	} else {
		return (
			<div>
				<ContestHeader />
			</div>
		);
	}
}
