const initialState = {
	// this needs to be removed from here down the line
	CodingContestList: [
		{
			id: 1,
			name: "Sample Test One",
		},
		{
			id: 2,
			name: "Sample Test Two",
		},
	],
};

export default function HomePageReducer(state = initialState, action) {
	switch (action.type) {
		// this action needs to be removed from here
		case "HomePage/UpdateCodingContestList":
			return {
				...state,
				CodingContestList: action.payload,
			};

		case "NUKE_STORE":
			return initialState;

		default:
			return state;
	}
}
