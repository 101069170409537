import {API_ENDPOINTS} from "./APIUrls.js";
import axios from "axios";

async function API_ReportError( error ){
    const errorStack = {
        "message" : JSON.stringify(error.stack),
    }
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    const axiosConfig = {
        url: API_ENDPOINTS.REPORTERROR,
        method: "post",
        data: errorStack,
        headers: headers,
    };
    try{
        axios( axiosConfig ); 
    }catch (error) {
        console.log(error)
    }
    return;
}

export {API_ReportError};