import axios from "axios";

export const generate_result = (esd,eed,exam_list) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("token")}`,
			Accept: "application/json",
		},
	};
	try {
		const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/results/generate_result_async/${esd}/${eed}/${exam_list}`, config);
        alert(res.data['Message']);
		}
	 catch (err) {
		alert("Sorry, Result generation failed !!");
		console.log(err.message);
	}
};