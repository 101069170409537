const API_ENDPOINTS = {
	RUNCODE: `${process.env.REACT_APP_API_BASE_URL}/api/code/runcode/`,
	SUBMITCODE: `${process.env.REACT_APP_API_BASE_URL}/api/code/submit/`,
	GETCONTESTQUESTIONS: `${process.env.REACT_APP_API_BASE_URL}/api/code/getquestions/`,
	GETCODINGCONTESTLIST: `${process.env.REACT_APP_API_BASE_URL}/api/code/getcontestlist/`,
	GETCODINGCONTESTDETAILS: `${process.env.REACT_APP_API_BASE_URL}/api/code/getcontest/`,
	//STARTCODINGCONTEST: `${process.env.REACT_APP_API_BASE_URL}/api/code/startcontest/`,
	FINISHCODINGCONTEST: `${process.env.REACT_APP_API_BASE_URL}/api/code/finishcontest/`,
	GETCODINGCONTESTTIMEREMAINING: `${process.env.REACT_APP_API_BASE_URL}/api/code/gettimeremaining/`,
	GETQUESTIONPREVIEW: `${process.env.REACT_APP_API_BASE_URL}/api/code/previewgetquestion/`,
	PREVIEWSUBMITCODE: `${process.env.REACT_APP_API_BASE_URL}/api/code/previewsubmit/`,
	REPORTERROR: `${process.env.REACT_APP_API_BASE_URL}/api/code/reporterror/`,
};

export { API_ENDPOINTS };
