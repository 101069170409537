import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {get_exam_list} from "../../actions/exam";
import {generate_result} from "../../actions/results";
import { Container, Row, Col, Card, Button, Form, Alert, CardColumns } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { Multiselect } from "multiselect-react-dropdown";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ResultForm() {
    const dispatch = useDispatch()	
	useEffect( () =>{
		dispatch(get_exam_list())

	},[])
	const {loading,exam_list,error} = useSelector((state) => state.exam)
    const [submitPressed, setSubmitPressed] = useState(false);
    const [checked, setChecked] = useState(false);
    const [formData, setFormData] = useState({
        exam_start_date:"NA",
        exam_end_date:"NA",
        exam_list:[]
    });

    
    function toggleExamDisplayValue() {setChecked(!checked);};
    function handleChange(e) {
        let changes = {};
        const val = e.target.value.trim()?e.target.value.trim():"NA";
        changes[e.target.name] = val;
        setFormData({ ...formData, ...changes });
    };
    
    const onSubmit = async (e) => {
        if(formData["exam_list"].length==0)
        alert("Select at least one exam !!");
        else
        {
            setSubmitPressed(true)
            await dispatch(generate_result(
                formData["exam_start_date"],
                formData["exam_end_date"],
                formData["exam_list"]));
            setSubmitPressed(false)
        }
    };
    function onSelect(selectedList) {
        let exam_list=selectedList.map(exam => (exam.pk));
        setFormData({...formData,...{exam_list:exam_list}})
    }

    if(loading){
		return (
				<div
					style={{
						height: "20vh",
						width: "100vw",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress />
				</div>)
	} else if(error){
        //console.log(error);
		const source =`# Oops !\n**No response from sever**\n 
	- Check your network connection
	- Refresh your page
	- Logout and login again`
		
		return (
			<div>
				<br /><Container className="main-home-container">
                <ReactMarkdown>{source}</ReactMarkdown>
				</Container> 
			</div>
		)

	} else {
	return (
		<div>
            <br /><br /><Container className="main-home-container">
            <Row className="justify-content-md-center d-flex align-items-centerf">
                <Card  style={{ width: '24rem' }}>
                            <div style={{ marginLeft: "7rem", marginTop: "2rem" ,marginBottom: "1rem"}}>
                                <Card.Title>{"Generate Result"}</Card.Title>
                                {/*<Card.Subtitle></Card.Subtitle>*/}
                            </div>
                            <Card.Text style={{ marginLeft: "2rem", marginRight: "2rem"}}>
                            <h6 id="checkbox">
                            Select Exams
                            </h6>
                            <label>
                                {"Search using exam id  "}
                                    <input type="checkbox" checked={checked} onChange={toggleExamDisplayValue}/>
                            </label>
                            <Multiselect
                            options={exam_list}
                            displayValue={checked ?"pk":"exam_name"}
                            onSelect={onSelect}
                            onRemove={onSelect}
                            showCheckbox={true}
                            />
                            <h6 id="checkbox" className="mt40">
                            Exam start date
                            </h6>
                            <input 
                            name="exam_start_date"
                            type="date"
                            onChange={handleChange}/>
                            <h6 id="checkbox" className="mt40">
                            Exam end date
                            </h6>
                            <input 
                            name="exam_end_date"
                            type="date"
                            onChange={handleChange}/>
                            </Card.Text>
                            <Button variant="primary" disabled={submitPressed} onClick={onSubmit}>Submit</Button>
                        </Card>
                    </Row>
    </Container>
		</div>
	);
}
}
