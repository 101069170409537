import { combineReducers } from 'redux';
import { LANGUAGES, MIME } from "../../langlist.js";
import { RESULTS_PANE_TABS } from "../../components/CodingPreviewAndTestPage/CodingConsole/ResultsPane.js";

const initialState = {
    "DarkTheme": false,
    "ResultsSectionTabSelection": 0,
    "SampleTestCaseSelection": 0,
    "CustomInputCheckBox": false,
    "RunLoading": false,
    "CustomRunLoading": false,
    "SubmitLoading": false,
    "isPageLoading": true,
}

export default function CodeUIReducer(state = initialState, action) {
    switch (action.type) {

        case "CODE/UPDATE_PAGE_LOADING_STATUS":
            return {
                ...state,
                "isPageLoading": action.payload,
            }

        case "CODE/CODEMIRROR_TOGGLE_DARK_THEME":
            return {
                ...state,
                "DarkTheme": action.payload,
            }

        case "CODE/SAMPLE_TEST_CASE_SELECTION":
            return {
                ...state,
                "SampleTestCaseSelection": action.payload,
            }

        case "CODE/TOGGLE_CUSTOM_INPUT_CHECK_BOX":
            return {
                ...state,
                "CustomInputCheckBox": action.payload,
            };

        case "CODE/RESULTS_SECTION_TAB_SELECTION":
            return {
                ...state,
                "ResultsSectionTabSelection": action.payload,
            }

        case "CODE/RESULTS_SECTION_RUN_TAB_LOADING":
            return {
                ...state,
                "RunLoading": action.payload,
            }

        case "CODE/RESULTS_SECTION_CUSTOM_RUN_TAB_LOADING":
            return {
                ...state,
                "CustomRunLoading": action.payload,
            }

        case "CODE/RESULTS_SECTION_SUBMIT_TAB_LOADING":
            return {
                ...state,
                "SubmitLoading": action.payload,
            }


        case "CODE/RUN_CODE":
            return {
                ...state,
                "ResultsSectionTabSelection": action.payload["ResultsSectionTabSelection"],
            };

        case "CODE/RUN_CODE_CUSTOM_INPUT":
            return {
                ...state,
                "ResultsSectionTabSelection": action.payload["ResultsSectionTabSelection"],
            };

        case "CODE/SUBMIT_CODE":
            return {
                ...state,
                "ResultsSectionTabSelection": action.payload["ResultsSectionTabSelection"],
            };
        case "NUKE_STORE":
                return initialState;

        default:
            return state;
    }
}
