import React from "react";
import { connect } from "react-redux";
import {
	Nav,
	Navbar,
	NavDropdown,
	Button,
	Form,
	InputGroup,
	FormControl,
	Image,
	Container,
	Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { logout } from "../actions/auth";

import "../styles/NavBar.css";
import logo from "./corporate-logo.png";

function NavBar({ user, logout }) {
	let user_profile_link = "#";
	if (user === null) {
		user = { username: "Account" };
	} else {
		user_profile_link = "/profile/" + user.username;
	}
	let admin_page_link = `${process.env.REACT_APP_API_BASE_URL}/admin/`;

	const handleLogout = (e) => {
		e.preventDefault();
		logout();
	};

	return (
		<Navbar bg="dark" expand="lg" className="justify-content-between">
			<Navbar.Brand as={Link} to="/home">
				<Container>
					<Row>
						<Image src={logo} className="logo"></Image>
					</Row>
				</Container>
			</Navbar.Brand>
			<Nav className="mr-auto">
				<Nav.Link as={Link} to="/home" id="basic-navbar-element">
					Home
				</Nav.Link>
				{/* <Nav.Link as={Link} to="/faq" id="basic-navbar-element">
					FAQs
				</Nav.Link> */}
			</Nav>

			{/* <Form inline className="search-bar-custom">
				<InputGroup>
					<InputGroup.Prepend>
						<Button variant="dark">Search</Button>
					</InputGroup.Prepend>
					<FormControl type="text" htmlSize="64" placeholder="Search for tests" className="form-control" />
				</InputGroup>
			</Form> */}

			<NavDropdown title={"@" + user.username} id="basic-navbar-element" alignRight as={Nav}>
				<NavDropdown.Item as={Link} to={user_profile_link}>
					My Profile
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/change_password">
					Change Password
				</NavDropdown.Item>
				{user.is_staff ? <NavDropdown.Item as={Link} to="/result">Results</NavDropdown.Item> : <div></div>}
				{user.is_staff ? <NavDropdown.Item href={admin_page_link}>Admin</NavDropdown.Item> : <div></div>}
				<NavDropdown.Divider />
				<NavDropdown.Item onClick={handleLogout} style={{ color: "red" }}>
					Logout
				</NavDropdown.Item>
			</NavDropdown>
		</Navbar>
	);
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(NavBar);
