import { combineReducers } from 'redux';
import { LANGUAGES, MIME } from "../../langlist.js";

const Question ={
    "ID": 1,
    "LatexText": "hello 123",
    "Title": "Title",
    "Language": LANGUAGES[0],
    "Mime": MIME[LANGUAGES[0]],
    "SampleTestCases": [],
    "SampleResults": [],
    "TerminalOutput": "",
    "SubmitResults": [],
    "CustomInput":"", 
}
for (const lang of LANGUAGES){
    Question[lang] = "";
}

const initialState = {
    "CurrentContestID": -1,
    "CurrentQuestionID": 1,
    "ContestQuestionIDList": [1,2,3],
    "1": Question,
    "2": Question,
    "3": Question,
};

export default function ConstestReducer( state = {} , action){
    switch(action.type){
        case "CODE/START_CONTEST_ATTEMPT":{
            return action.payload;
        }
        
        case "CODE/SELECT_QUESTION":{
            return {
                ...state,
                "CurrentQuestionID": action.payload,
            };
        }
        case "CODE/UPDATE_CODE":{
            const QID = action.payload["QuestionID"];
            const lang = action.payload["Language"];
            const updatedQ = {
                ...(state[QID]),
            };
            updatedQ[lang] = action.payload["Code"];
            const updatedState = {
                ...state,
            };
            updatedState[QID] = updatedQ;
            return updatedState;
        }
        case "CODE/CHANGE_LANGUAGE":{
            const QID = action.payload["QuestionID"];
            const lang = action.payload["Language"];
            const updatedQ = {
                ...(state[QID]),
                "Language": lang,
                "Mime":MIME[lang],
            };
            const updatedState = {
                ...state,
            };
            updatedState[QID] = updatedQ;
            return updatedState;
        }
        case "CODE/UPDATE_CUSTOM_INPUT":{
            const QID = action.payload["QuestionID"];
            const CustomInput = action.payload["CustomInput"];
            const updatedQ = {
                ...(state[QID]),
                "CustomInput": CustomInput,
            };
            const updatedState = {
                ...state,
            };
            updatedState[QID] = updatedQ;
            return updatedState;
        }
        case "CODE/RUN_CODE":{
            const QID = action.payload["QuestionID"];
            const SampleResults = action.payload["SampleResults"];
            const updatedQ = {
                ...(state[QID]),
                "SampleResults": SampleResults,
            };
            const updatedState = {
                ...state,
            };
            updatedState[QID] = updatedQ;
            return updatedState;
        }
        case "CODE/RUN_CODE_CUSTOM_INPUT":{
            const QID = action.payload["QuestionID"];
            const output = action.payload["TerminalOutput"];
            const updatedQ = {
                ...(state[QID]),
                "TerminalOutput": output,
            };
            const updatedState = {
                ...state,
            };
            updatedState[QID] = updatedQ;
            return updatedState;
        }
        case "CODE/SUBMIT_CODE":{
            const QID = action.payload["QuestionID"];
            const SubmitResults = action.payload["SubmitResults"];
            const updatedQ = {
                ...(state[QID]),
                "SubmitResults": SubmitResults,
            };
            const updatedState = {
                ...state,
            };
            updatedState[QID] = updatedQ;
            return updatedState;
        }
        case "CODE/FINISH_CONTEST_ATTEMPT":{
            return {};
        }
        case "NUKE_STORE":{
            return initialState;
        }
        default:{
            return state;
        }
    }
}
