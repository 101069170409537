import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect, Link, useHistory } from "react-router-dom";
import { check_authenticated, logout } from "../actions/auth";
import { get_live_exams_for_user } from "../actions/exam";
import { EXAM_MANAGER } from "../actions/types";
import NavBar from "../components/NavBar";
import Announcement from "../components/HomePage/Announcement"
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import "../styles/HomePage/HomePage.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import userEvent from "@testing-library/user-event";

function HomePage({ get_live_exams_for_user }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const [isPageLoading, UpdateisPageLoading] = useState(true);
	useEffect(() => {
		const FetchPage = async (dispatch) => {
			await get_live_exams_for_user();
			dispatch({ type: EXAM_MANAGER.REMOVE_CURRENT_EXAM_INFORMATION });
			UpdateisPageLoading(false);
		};

		FetchPage(dispatch);
	}, []);

	const LiveExams = useSelector((state) => state.exam.live_exams_list);

	const JSXLiveTestCard = LiveExams.map((value, index) => {
		let exam_end_time = new Date(value.exam_end_time);
		let exam_end_date = exam_end_time.toDateString();
		exam_end_time = exam_end_time.toTimeString().split(" ")[0];
		let exam_total_time = value.exam_total_time;
		exam_total_time =
			exam_total_time.slice(-9, -7) + ":" + exam_total_time.slice(-6, -4) + ":" + exam_total_time.slice(-3, -1);

		return (
			<Col xl={4} lg={4} md={4} style={{ marginBottom: "1rem" }}>
				<Paper className="exam-card-surface" elevation={6}>
					<Card className="exam-card">
						<div style={{ marginLeft: "1rem", marginTop: "1rem" }}>
							<Card.Title>{`${value.exam_name}`}</Card.Title>
							<Card.Subtitle>{value.exam_comments}</Card.Subtitle>
						</div>
						<Card.Text style={{ marginLeft: "2rem", marginTop: "1rem" }}>
									<b>Test duration:</b> {exam_total_time}
						</Card.Text>
						<Button
							bsPrefix="btn exam-card-button"
							variant="outline-light"
							onClick={(e) => {
								e.preventDefault();
								dispatch({ type: EXAM_MANAGER.SET_CURRENT_EXAM_STATE, payload: value });
								history.push("/exam/" + value.pk);
							}}
						>
							GO TO TEST
						</Button>
					</Card>
				</Paper>
			</Col>
		);
	});

	if (isPageLoading) {
		return (
			<div
				style={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	} else {
		return (
			<div>
				<NavBar />
				<Announcement />
				<Container className="main-home-container">
					<Row bsPrefix="row exam-list-header">
						<h1>Live Tests</h1>
						<hr />
					</Row>
					<Row className="exam-list-row">{JSXLiveTestCard}</Row>
				</Container>
			</div>
		);
	}
}

export default connect(null, { get_live_exams_for_user })(HomePage);
