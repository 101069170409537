import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import auth from "./auth";
import updateTestManagerState from "./mcq";
import user_profile from "./user_profile";
import HomePageReducer from "./HomePage.js";
import AnnouncementReducer from "./announcement.js";
import CodeReducer from "./Code/combinedReducer";
import ExamReducer from "./exam";

const persistConfig = {
	key: "root",
	storage: storage,
	blacklist: ["Code", "HomePage"],
};

const TemprootReducer = combineReducers({
	auth: auth,
	mcq: updateTestManagerState,
	user_profile: user_profile,
	HomePage: HomePageReducer,
	Announcement : AnnouncementReducer,
	Code: CodeReducer,
	exam: ExamReducer,
});

const rootReducer = persistReducer(persistConfig, TemprootReducer);

export default rootReducer;
