import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useStore, useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import SplitPane from "react-split-pane";

import CodingPreviewBar from "../components/CodingPreviewAndTestPage/CodingPreviewBar";
import CodingQuestionPane from "../components/CodingPreviewAndTestPage/CodingQuestionPane";
import CodingConsolePane from "../components/CodingPreviewAndTestPage/CodingConsolePane";


import NavBar from "../components/NavBar";
//import ContestHeader from "../components/ContestHeader";
import { ReactReduxContext } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API_GetTimeRemaining, API_GetPreviewQuestion, API_GetContestState } from "./APIHelpers.js";
import { APIErrorBasedRedirect } from "../rootAPIHelpers.js";
import {API_ReportError} from "../ReportError"


function FetchTimeRemaining() {
	return async (dispatch, getState) => {
		const action = {
			type: "CODE/UPDATE_TIME_REMAINING",
			payload: 1000,//assuming this is in seconds
		};
		dispatch(action);

		return Promise.resolve();
	};
}

function FetchCodingTestDetails(QuestionID, history) {
	return async (dispatch, getState) => {
                try{
		    var ContestStateSlice = await API_GetPreviewQuestion(QuestionID);
                }catch(error){
					API_ReportError(error);
                    // you should think about effects on redux-persist
                    APIErrorBasedRedirect(error.httpStatus, history);
                    return;
                }
		const action = {
			type: "CODE/START_CONTEST_ATTEMPT",
			payload: ContestStateSlice,
		};
		dispatch(action);
		return Promise.resolve();
	};
}

function SetCodingPageLoadingStatus(Status) {
	return (dispatch) => {
		const action = {
			type: "CODE/UPDATE_PAGE_LOADING_STATUS",
			payload: Status,
		};
		dispatch(action);
	};
}

function CheckStateIntegrity(Code) {
	/*
        if (Code.Contest["CurrentContestID"] === Code.ContestInfo["ContestID"]) {
		return true;
	} else {
		return false;
	}
        */
        return true;
}

function ResetContestInfo() {
	return (dispatch) => {
		const action = {
			type: "CODE/RESET_CONTEST_INFO",
		};
		dispatch(action);
	};
}

function CodingQuestionPreviewPage({}) {
	useEffect(() => {
		document.title = "Coding Preview";
	});
        
        const {pk:QuestionID} = useParams();

	const state_ref = useStore().getState().Code;
	const dispatch = useDispatch();
	const history = useHistory();

	//Loading related code for the whole page
	const LoadingStatus = useSelector((state) => state.Code.CodeUI["isPageLoading"]);
	useEffect(() => {
		// we need to make sure that Loading status is true
		dispatch(SetCodingPageLoadingStatus(true));
		//is the following cleanup function necessary?
		return () => {
			dispatch(SetCodingPageLoadingStatus(true));
		};
	}, []);
	//useEffect is called in the same order as  in the code.
	useEffect(() => {
		const StartContest = async function (dispatch) {
			await dispatch(FetchCodingTestDetails(QuestionID,history));
			await dispatch(FetchTimeRemaining());
			dispatch(SetCodingPageLoadingStatus(false));
		};
		StartContest(dispatch);
	}, []);
	//Finished with loading related code

	if (!LoadingStatus) {
		return (
			<div>
				<div className="App">
					<NavBar />
					<CodingPreviewBar />

					<SplitPane
						split="vertical"
						defaultSize="60vw"
						maxSize={1000}
						minSize={550}
						primary="second"
						style={{ display: "flex", height: "calc(100% - 28.5mm)" }}
					>
						<CodingQuestionPane />
						<CodingConsolePane questionType = {{isPreview: true}} />
					</SplitPane>
				</div>
			</div>
		);
	} else {
		return (
			<div
				style={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {})(CodingQuestionPreviewPage);
