import React from "react";
import { Route, Redirect,useLocation} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { check_authenticated, logout } from "../../actions/auth";

const AuthenticatedRoute = ({ component: Component, check_authenticated, logout, ...rest }) => {
	check_authenticated();
	let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	let location = useLocation();
	if (isAuthenticated) {
		return <Route component={Component} {...rest} />;
	} else {
		logout();
		return <Redirect to={{
			pathname: "/",
			state: { from: location }
		  }} />;
	}
};

export default connect(null, { check_authenticated, logout })(AuthenticatedRoute);
// dummy line
