import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Jumbotron, Container, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import ContestHeader from "../components/ContestHeader";

import { API_GetTimeRemaining, API_GetContestQuestions, API_GetContestState } from "./APIHelpers.js";

//function FetchCodingContestTimings(ID){
//    return async (dispatch) => {
//        const Time = await API_GetContestTimings(ID);
//        const action = {
//            "type":"CODE/FETCH_CODING_CONTEST_TIMINGS",
//            "payload":Time,
//        };
//        dispatch(action);
//    }
//}

function FetchTimeRemaining() {
	return async (dispatch, getState) => {
		const ContestID = getState().Code.ContestInfo["ContestID"];
		const timeRemaining = await API_GetTimeRemaining(ContestID);
		//console.log(timeRemaining);
		const action = {
			type: "CODE/UPDATE_TIME_REMAINING",
			payload: timeRemaining,
		};
		dispatch(action);

		return Promise.resolve();
	};
}

function FetchCodingContestState(ID) {
	return async (dispatch) => {
		const ContestState = await API_GetContestState(ID);
		const action = {
			type: "CODE/FETCH_CODING_CONTEST_STATE",
			payload: ContestState,
		};
		dispatch(action);

		return Promise.resolve();
	};
}

function StartCodingTestAttempt() {
	return async (dispatch, getState) => {
		const ContestID = getState().Code.ContestInfo["ContestID"];

		//await API_StartContest(ContestID); //Maybe deal with this in a better way???

		//assuming backend does not create a second attempt if one already exists!!!
		const ContestStateSlice = await API_GetContestQuestions(ContestID);
		const action = {
			type: "CODE/START_CONTEST_ATTEMPT",
			payload: ContestStateSlice,
		};
		dispatch(action);

		return Promise.resolve();
	};
}

function ResumeCodingTestAttempt() {
	return async (dispatch, getState) => {
		const ContestID = getState().Code.ContestInfo["ContestID"];

		const isStarted = getState().Code.ContestInfo["isStarted"];
		//this if else might be unnecessary now!
		if (isStarted) {
			return Promise.resolve();
		} else {
			const ContestStateSlice = await API_GetContestQuestions(ContestID);
			const action = {
				type: "CODE/START_CONTEST_ATTEMPT",
				payload: ContestStateSlice,
			};
			dispatch(action);
			return Promise.resolve();
		}
	};
}

function CodingTestDetailsPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const ContestID = useSelector((state) => state.Code.ContestInfo["ContestID"]);
	//effect to check whther contestID is -1
	useEffect(() => {
		if (ContestID === -1) {
			console.log("second");
			history.push("/");
		}
	}, []);
	const contestName = useSelector((state) => state.Code.ContestInfo["name"]);
	const instructions = useSelector((state) => state.Code.ContestInfo["instructions"]);
	const duration = new Date(useSelector((state) => state.Code.ContestInfo["duration"]) * 1000)
		.toISOString()
		.substr(11, 8);
	const totalQuestions = useSelector((state) => state.Code.ContestInfo["totalQuestions"]);

	//effect to fetch status of contest once at componentMount

	//effect to fetch timings once at componentMount

	//useEffect(
	//    () => {
	//        dispatch( FetchCodingContestTimings(ContestID) );
	//    },
	//    []
	//);
	const [isPageLoading, UpdateisPageLoading] = useState(true);
	//effect to fetch contest state for the contestant once at componentMount
	useEffect(() => {
		const FetchPage = async function (dispatch) {
			await dispatch(FetchCodingContestState(ContestID));
			UpdateisPageLoading(false);
		};

		FetchPage(dispatch);
	}, []);

	const StartTime = useSelector((state) => state.Code.ContestInfo["StartTime"]);
	const EndTime = useSelector((state) => state.Code.ContestInfo["EndTime"]);
	//const isContestAttemptFinished = useSelector( state => state.Code.ContestInfo["ContestAttemptFinished"] );
	//const isContestAttemptStarted = useSelector( state => state.Code.ContestInfo["ContestAttemptStarted"] );
	const isContestLive = useSelector((state) => state.Code.ContestInfo["ContestLive"]);
	const isContestAttemptStartedBackend = useSelector((state) => state.Code.ContestInfo["isStarted"]);
	const isContestAttemptFinishedBackend = useSelector((state) => state.Code.ContestInfo["isFinished"]);

	const ContestState = useSelector((state) => state.Code.Contest); //do we need to worry about re-rendering when this updates???
	//assuming ContestState will be empty if contest was ever finished by contestant. If for some reason, the initialState is fetched, it also checks for that.
	const isProgressFetched = "CurrentContestID" in ContestState && ContestState["CurrentContestID"] != -1;

	const isFetchedProgressDifferentContest = !(ContestID === ContestState["CurrentContestID"]);

	let ContestMessage;

	if (!isContestLive) {
		ContestMessage = <h2>Test Is Not Open</h2>;
	} //we might need to tweak this. Is the contestant allowed to attempt outside of active hours, if the contest has already been started?
	else if (!isContestAttemptStartedBackend) {
		if (isFetchedProgressDifferentContest && isProgressFetched) {
			//there is progress fetched from some other contest
			ContestMessage = (
				<div>
					<div style={{ width: "100%", textAlign: "right" }}>
						<Button
							type="button"
							onClick={async (e) => {
								//await dispatch( StartCodingTestAttempt() );
								//await dispatch( FetchTimeRemaining() );
								history.push("/coding/test/attempt");
								//window.open("/coding/test/attempt");
							}}
						>
							Start Test
						</Button>
					</div>
					<p>
						{" "}
						Be warned that we cannot store progress(written code)for two different contests. This means that
						if you try to resume the other contest after starting this one, whatever code you had written
						will be lost. But your submissions have been saved and your scores will be accurate.
					</p>
				</div>
			);
		} else {
			ContestMessage = (
				<div style={{ width: "100%", textAlign: "right" }}>
					<Button
						type="button"
						onClick={async (e) => {
							//await dispatch( StartCodingTestAttempt() );
							//await dispatch( FetchTimeRemaining() );
							history.push("/coding/test/attempt");
							//window.open("/coding/test/attempt");
						}}
					>
						Start Test
					</Button>
				</div>
			);
		}
	} else if (isContestAttemptFinishedBackend) {
		ContestMessage = <h2>It seems you have finished the Test/ Ran out of Time!</h2>;
	} else if (!isProgressFetched) {
		ContestMessage = (
			<div>
				<div style={{ width: "100%", textAlign: "right" }}>
					<Button
						type="button"
						onClick={async (e) => {
							//await dispatch( StartCodingTestAttempt() );
							//await dispatch( FetchTimeRemaining() );
							history.push("/coding/test/attempt");
							//window.open("/coding/test/attempt");
						}}
					>
						Resume Test
					</Button>
				</div>
				<p>
					{" "}
					Unfortunately, Your browser Cache does not have details of your attempt, which means we cannot fetch
					your progress. But, fret not, all your submissions have been saved!{" "}
				</p>
			</div>
		);
	} else if (!isFetchedProgressDifferentContest) {
		ContestMessage = (
			<div>
				<div style={{ width: "100%", textAlign: "right" }}>
					<Button
						type="button"
						onClick={async (e) => {
							//await dispatch( ResumeCodingTestAttempt() );
							//await dispatch( FetchTimeRemaining() );
							history.push("/coding/test/attempt");
							//window.open("/coding/test/attempt");
						}}
					>
						Resume Test
					</Button>
				</div>
				<p> We will fetch your progress from your browser Cache!</p>
			</div>
		);
	} else {
		ContestMessage = (
			<div>
				<div style={{ width: "100%", textAlign: "right" }}>
					<Button
						type="button"
						onClick={async (e) => {
							//await dispatch( ResumeCodingTestAttempt() );
							//await dispatch( FetchTimeRemaining() );
							history.push("/coding/test/attempt");
							//window.open("/coding/test/attempt");
						}}
					>
						Resume Test
					</Button>
				</div>
				<p>
					{" "}
					It seems you are trying to attempt a different contest from the one you attempted earlier. We cannot
					save code progress for multiple contests.
				</p>
			</div>
		);
	}

	if (isPageLoading) {
		return (
			<div
				style={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	} else {
		return (
			<div>
				<ContestHeader />
				<br />
				<Container>
					<Jumbotron as={Paper} style={{ paddingTop: "10px", paddingBottom: "10px", margin: "0px" }}>
						<Row className="d-flex justify-content-center">
							<h1>{contestName}</h1>
						</Row>
						<hr />
						<Row className="justify-content-left" style={{ width: "100%" }}>
							<h5 style={{ width: "100%" }}>
								<ul style={{ width: "calc(100% - 40px)", marginLeft: "40px" }}>
									<li>
										Test Details
										<ul style={{ marginLeft: "45px", fontSize: "large", fontWeight: "normal" }}>
											<li>Test type: Coding</li>
											<li>Test duration: {duration}</li>
											<li>No. of questions: {totalQuestions}</li>
										</ul>
									</li>
									<li>
										Instructions
										<ul style={{ marginLeft: "45px", fontSize: "large", fontWeight: "normal" }}>
											{instructions.split("\n").map((value, index) => {
												return <li key={index}>{value}</li>;
											})}
										</ul>
									</li>
								</ul>
							</h5>
						</Row>
						<br />
						<Row className="justify-content-end">{ContestMessage}</Row>
					</Jumbotron>
				</Container>
			</div>
		);
	}
}

export default CodingTestDetailsPage;
