import axios from "axios";
import { EXAM_MANAGER } from "./types";
import {API_ReportError} from "../ReportError.js"
export const get_live_exams_for_user = () => async (dispatch) => {
	if (localStorage.getItem("token")) {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
				Accept: "application/json",
			},
		};

		try {
			const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/manager/live_exams/`, config);
			dispatch({ type: EXAM_MANAGER.GET_LIVE_EXAMS_SUCCESS, payload: res.data });
		} catch (err) {
			dispatch({ type: EXAM_MANAGER.GET_LIVE_EXAMS_FAIL });
			console.error(err.response);
			API_ReportError(err);
		}
	} else {
		dispatch({ type: "NUKE_STORE" });
		// logout and nuke store
	}
};

export const get_exam_state_details = (pk) => async (dispatch) => {
	if (localStorage.getItem("token")) {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
				Accept: "application/json",
			},
		};

		try {
			const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/manager/exam_manager/${pk}/`, config);

			dispatch({ type: EXAM_MANAGER.GET_EXAM_SECTIONS_STATE_SUCCESS, payload: res.data });
		} catch (err) {
			dispatch({ type: EXAM_MANAGER.GET_EXAM_SECTIONS_STATE_FAIL });
			console.error(err.response);
			API_ReportError(err);
		}
	} else {
		dispatch({ type: "NUKE_STORE" });
		// logout and nuke store
	}
};

export const get_exam_run = (pk) => async (dispatch) => {
	if (localStorage.getItem("token")) {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
				Accept: "application/json",
			},
		};

		try {
			const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/manager/exam_runner/${pk}/`, config);
			dispatch({ type: EXAM_MANAGER.GET_CURRENT_EXAM_RUNNER_SUCCESS, payload: res.data });
		} catch (err) {
			// dispatch({ type: EXAM_MANAGER.GET_EXAM_SECTIONS_STATE_FAIL });
			console.error(err.response);
			API_ReportError(err);
		}
	} else {
		dispatch({ type: "NUKE_STORE" });
		// logout and nuke store
	}
};

export const get_existing_exam_run = (pk) => async (dispatch) => {
	if (localStorage.getItem("token")) {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
				Accept: "application/json",
			},
		};

		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/api/manager/existing_exam_runner/${pk}/`,
				config
			);
			dispatch({ type: EXAM_MANAGER.GET_CURRENT_EXAM_RUNNER_SUCCESS, payload: res.data });
		} catch (err) {
			// dispatch({ type: EXAM_MANAGER.GET_EXAM_SECTIONS_STATE_FAIL });
			console.error(err.response);
			API_ReportError(err);
		}
	} else {
		dispatch({ type: "NUKE_STORE" });
		// logout and nuke store
	}
};

export const update_exam_runner = (pk, body) => async (dispatch) => {
	if (localStorage.getItem("token")) {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
				Accept: "application/json",
			},
		};

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_BASE_URL}/api/manager/exam_runner/${pk}/`,
				body,
				config
			);
			dispatch({ type: EXAM_MANAGER.UPDATE_CURRENT_EXAM_RUNNER_SUCCESS, payload: res.data });
		} catch (err) {
			// dispatch({ type: EXAM_MANAGER.GET_EXAM_SECTIONS_STATE_FAIL });
			console.error(err.response);
			API_ReportError(err);
		}
	} else {
		dispatch({ type: "NUKE_STORE" });
		// logout and nuke store
	}
};

export const get_exam_list = () => async (dispatch) => {
	if (localStorage.getItem("token")) {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
				Accept: "application/json",
			},
		};

		try {
            dispatch({type : EXAM_MANAGER.GET_EXAM_LIST_REQUEST});
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/manager/list_all_exams/`,config);
			
            dispatch({
                type : EXAM_MANAGER.GET_EXAM_LIST_SUCCESS,
                payload :res.data
            })
		} catch (err) {
			dispatch({ 
                type: EXAM_MANAGER.GET_EXAM_LIST_FAIL,
				payload : err.message
            });
		}
	} else {
		dispatch({ type: "NUKE_STORE" });
		// logout and nuke store
	}
}
