import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { check_authenticated } from "../actions/auth";
import { get_exam_state_details, get_exam_run, get_existing_exam_run } from "../actions/exam";
import { EXAM_MANAGER } from "../actions/types";
import ContestHeader from "../components/ContestHeader";
import ExamInstructions from "../components/HomePage/ExamInstructions";
import { Container, Row, Col, Card, Button, Table } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import "../styles/HomePage/CombinedTestsDetailsPage.css";

function GoToCodingContest(ID) {
	return (dispatch) => {
		const action = {
			type: "CODE/SET_CONTEST_ID",
			payload: ID,
		};
		dispatch(action);
	};
}

function CombinedTestsDetailsPage({ get_exam_state_details, get_exam_run, get_existing_exam_run }) {
	const { pk } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const [isPageLoading, UpdateisPageLoading] = useState(true);
	const live_exams_list = useSelector((state) => state.exam.live_exams_list);
	useEffect(() => {
		const FetchPage = async () => {
			await get_exam_state_details(pk);
			await get_existing_exam_run(pk);
			for (let a = 0; a < live_exams_list.length; a++) {
				if (String(live_exams_list[a].pk) === pk) {
					dispatch({ type: EXAM_MANAGER.SET_CURRENT_EXAM_STATE, payload: live_exams_list[a] });
				}
			}
			UpdateisPageLoading(false);
		};
                //Timeout is being used because if the Django DB update race condition
		setTimeout(FetchPage,2000);
	}, []);

	const ExamSectionDetails = useSelector((state) => state.exam.current_exam_manager.exam_sections);
	const ExamDetails = useSelector((state) => state.exam.current_exam_manager.exam_details);

	const JSXExamSectionDetailsTable = ExamSectionDetails.map((value, index) => {
		let button_variant;
		let disabled = true;
                const value_type_beautify = (value_type) => {
                    if(value_type==="coding") return "Coding";
                    else if(value_type==="mcq") return "MCQ";
                    else return "UNKNOWN";
                };
		if (value.button_text === "Resume") {
			button_variant = "warning";
			disabled = false;
		}

		if (value.button_text === "Begin") {
			button_variant = "primary";
			disabled = false;
		}

		if (value.button_text === "Completed") {
			button_variant = "success";
		}

		if (value.button_text === "Complete previous") {
			button_variant = "danger";
		}

		if (value.button_text === "Finished") {
			button_variant = "dark";
		}

		return (
			<tr>
				<th>{value.num}</th>
				<th>{value.name}</th>
				<th>{value_type_beautify(value.type)}</th>
				<th>
					<Button
						disabled={disabled}
						variant={button_variant}
						onClick={(e) => {
							e.preventDefault();
							get_exam_run(pk);
							if (value.type == "coding") {
								dispatch(GoToCodingContest(value.pk));
							}
							history.push(value.button_link);
						}}
					>
						{value.button_text}
					</Button>
				</th>
			</tr>
		);
	});

	if (isPageLoading) {
		return (
			<div
				style={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	} else {
		return (
			<div>
				<ContestHeader />
				<br />
				<Container>
					<Row style={{ marginBottom: "1rem" }}>
						<Col className="exam-sections-column">
							<Paper elevation={6}>
								<Table
									style={{ textAlign: "center" }}
									striped
									bordered
									hover
									className="exam-sections-table"
								>
									<thead>
										<tr>
											<th colSpan="4">
												<h1>Test Structure</h1>
											</th>
										</tr>
										<tr>
											<th>Sl No.</th>
											<th>Name</th>
											<th>Test Type</th>
											<th>Test Link</th>
										</tr>
										{JSXExamSectionDetailsTable}
									</thead>
								</Table>
							</Paper>
						</Col>
					</Row>

				</Container>
			</div>
		);
	}
}

export default connect(null, { check_authenticated, get_exam_state_details, get_exam_run, get_existing_exam_run })(
	CombinedTestsDetailsPage
);
