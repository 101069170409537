import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card } from "react-bootstrap";
import Modal from "react-modal";
import ReactMarkdown from "react-markdown";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import Paper from "@material-ui/core/Paper";

import {get_announcements} from "../../actions/announcement";
import {API_ReportError} from "../../ReportError"

export default function Announcement() {
	
	const dispatch = useDispatch()	
	useEffect( () =>{
		dispatch(get_announcements())

	},[])
	const Announcements = useSelector((state) => state.Announcement)
	const { error,announcement_list, loading} = Announcements
	
	const [isModalOpen, setModalOpen] = useState(false);
    function toggleModal() {
        setModalOpen(!isModalOpen);
      }
    const customStyles = {
	overlay: {
		position: 'fixed',
		top: '18%',
		left: '10%',
		right: '10%',
		bottom: '5%',
		backgroundColor: 'rgba(255, 255, 255, 0.75)'
	}
	};

	function AnnouncementCard({title,text}) {
		return (
			<Col xl={4} lg={4} md={4} style={{ marginBottom: "1rem", width: "27rem"}}>
				<Paper className="exam-card-surface" elevation={6}>
					<Card className="exam-card">
						<div style={{ marginLeft: "1rem", marginTop: "1rem" }}>
							<Card.Title>{title}</Card.Title>
							{/*<Card.Subtitle></Card.Subtitle>*/}
						</div>
						<Card.Text style={{ marginLeft: "1rem" }}>
							<ReactMarkdown>
								{text}
							</ReactMarkdown>
						</Card.Text>
					</Card>
				</Paper>
			</Col>)
	}
	
	if(loading){
		return (
				<div
					style={{
						height: "20vh",
						width: "100vw",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress />
				</div>)
	} else if(error){
		API_ReportError(error);
		const source =`# Oops !\n**Cannot load announcements**\n 
	- Check your network connection
	- Refresh your page
	- Logout and login again`
		
		return (
			<div>
				<br /><Container className="main-home-container">
				<Row bsPrefix="row exam-list-header">
					<h1>Announcements hehe &nbsp;</h1><CampaignOutlinedIcon style={{ fontSize: 70 }}/>
				</Row>
				 <ReactMarkdown>{source}</ReactMarkdown>
				 </Container> 
			</div>
		)

	} else {
		return (
				<><br /><Container className="main-home-container">
				<Row bsPrefix="row exam-list-header">
					<Button onClick={toggleModal}><h1 style={{"text-transform":"none"}}>Announcements &nbsp;</h1><CampaignOutlinedIcon style={{ fontSize: 70 }}/></Button>
					<Modal
						isOpen={isModalOpen}
						onRequestClose={toggleModal}
						contentLabel="All Announcements"
						style={customStyles}
						>
						<div class="modal-header">
							<h5 class="modal-title">All Announcements</h5>
							<button onClick={toggleModal} type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<br />
						<div>
							<Row>{announcement_list.map(announcement => (
								<AnnouncementCard title={announcement.title} text={announcement.text}/>
							))}</Row></div>
					</Modal>
					<hr />
					{/*<p>
						Please Read these important announcements.
	</p>*/}
				</Row>
				<Row className="exam-list-row">
					{announcement_list.map(announcement => (
						<>
						{ announcement.display_on_homepage == 'y' && 
							<AnnouncementCard title={announcement.title} text={announcement.text}/>
						}</>
					))}
				</Row>
			</Container><br /></>
		);
	}}
