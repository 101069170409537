import axios from "axios";
import {RESULT_TO_PASS_STATUS} from "../../runResultTypesMapping.js";
import {API_ENDPOINTS} from "../../APIUrls.js";
import {RESULTS_PANE_TABS} from "./CodingConsole/ResultsPane.js";
import {API_ReportError} from "../../ReportError"


async function API_FinishContest(ContestID){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    
    const axiosConfig = {
        url: `${API_ENDPOINTS.FINISHCODINGCONTEST}${ContestID}/`,
        method: "post",
        headers: headers,
    };
   
    try{
        var response = await axios( axiosConfig );

    }catch(error){
        console.log(error);
        API_ReportError(error);
    }

    // you should return true after checking the contents of the return body
    return true;

    // you should return false after checking the contents of the return body



}

export {API_FinishContest };
