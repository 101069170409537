import axios from "axios";
import { AUTH } from "./types";
import { browserHistory } from "react-router";
import {API_ReportError} from "../ReportError.js";

export const check_authenticated = () => async (dispatch) => {
	try {
		let token_expiry = localStorage.getItem("token_expiry");
		token_expiry = Date.parse(token_expiry);
		let current_time = new Date();
		current_time = Date.parse(current_time);
		if (token_expiry > current_time) {
			dispatch({ type: "AUTH_SUCCESS" });
		} else {
			dispatch({ type: "AUTH_FAIL" });
		}
	} catch {
		dispatch({ type: "AUTH_FAIL" });
	}

	//    dispatch({ type: "AUTH_SUCCESS"});
};

export const load_user = (pk) => async (dispatch) => {
	if (localStorage.getItem("token")) {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
				Accept: "application/json",
			},
		};

		try {
			const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/retrieve/${pk}/`, config);

			dispatch({
				type: "USER_LOADED_SUCCESS",
				payload: res.data,
			});
		} catch (err) {
			dispatch({
				type: "USER_LOADED_FAIL",
			});
		}
	} else {
		dispatch({ type: "USER_LOADED_FAIL" });
	}
};

export const login = (formData, setHelperTexts, helperTexts) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	const body = JSON.stringify({ username: formData.email, password: formData.password });

	try {
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/login/`, body, config);
		localStorage.setItem("token", res.data.token);
		localStorage.setItem("token_expiry", res.data.expiry);
		dispatch({
			type: "LOGIN_SUCCESS",
			payload: res.data,
		});
		dispatch(load_user(res.data.pk));
		setHelperTexts({
			...helperTexts,
			password: "",
			email: "",
		});
	} catch (err) {
		localStorage.removeItem("token");
		localStorage.removeItem("token_expiry");
		dispatch({
			type: "LOGIN_FAIL",
		});
		if (err.response.data.non_field_errors) {
			alert(err.response.data.non_field_errors[0]);
		}
		setHelperTexts({
			...helperTexts,
			password: err.response.data.password,
			email: err.response.data.username,
		});
	}
};

export const logout = () => async (dispatch) => {
	if (localStorage.getItem("token")) {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${localStorage.getItem("token")}`,
				Accept: "application/json",
			},
		};
		try {
			const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/logout/`, {} ,config);
		} catch (err) {
			console.error(err)
			API_ReportError(err);
		}
	}
	localStorage.removeItem("token");
	localStorage.removeItem("token_expiry");
	dispatch({ type: AUTH.NUKE_STORE });
};

export const register = (formData, setHelperTexts, helperTexts) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	const body = JSON.stringify({
		username: formData.username,
		email: formData.email,
		first_name: formData.first_name,
		last_name: formData.last_name,
	});

	try {
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/register/`, body, config);
		if (res.data === "email sent") {
			setHelperTexts({
				...helperTexts,
				username: "",
				first_name: "",
				last_name: "",
				email: "",
			});
			// check email notification -> click ok -> clear all fields
			alert("An email has been sent to " + formData.email + " with instructions to complete sign up process");
			window.location.reload();
		}
	} catch (err) {
		setHelperTexts({
			...helperTexts,
			username: err.response.data.username,
			first_name: err.response.data.first_name,
			last_name: err.response.data.last_name,
			email: err.response.data.email,
		});
	}
};

export const validate_uid_token = (uid, token) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_BASE_URL}/api/users/set_password/${uid}/${token}/`,
			config
		);
		if (res.data.info === "valid uid and token") {
			localStorage.setItem("token", res.data.token);
			localStorage.setItem("token_expiry", res.data.expiry);
			dispatch({ type: AUTH.SET_PASSWORD_GET_SUCCESS, payload: { uid, token } });
			dispatch(load_user(res.data.pk));
		} else {
			dispatch({ type: AUTH.SET_PASSWORD_GET_FAIL });
			return "invalid_link";
		}
	} catch (err) {
		dispatch({ type: AUTH.SET_PASSWORD_GET_FAIL });
		return "invalid_link";
	}
};

export const set_password = (formData) => async (dispatch, getState) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Token ${localStorage.getItem("token")}`,
			Accept: "application/json",
		},
	};

	let body = {};
	let setPassword = formData.setPassword;
	if (setPassword === formData.confirmPassword) {
		if (setPassword.length >= 8) {
			if (!/\d/.test(setPassword)) {
				return "your password should contain atleast one numeric";
			}
		} else {
			return "your password needs to be atleast 8 characters long including atleast one numeric";
		}
	} else {
		return "The passwords you entered do not match. Please try again";
	}

	body = JSON.stringify({ password: setPassword });
	const uid = getState().auth.uid;
	const token = getState().auth.set_password_token;

	try {
		const res = await axios.post(
			`${process.env.REACT_APP_API_BASE_URL}/api/users/set_password/${uid}/${token}/`,
			body,
			config
		);
		if (res.data === "password has been updated!!") {
			return res.data;
		} else {
			dispatch(logout());
			return res.data;
		}
	} catch (err) {
		dispatch(logout());
		return null;
	}
};

export const nuke_store = () => async (dispatch) => {
	dispatch({ type: "NUKE_STORE" });
};

export const forgot_password_send_email = (email) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	const body = JSON.stringify({ email });

	try {
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/forgot_password/`, body, config);
		if (res.data === "email sent") {
			// check email notification -> click ok -> clear all fields
			alert("An email has been sent to " + email + " with link to reset password");
			window.location.reload();
		} else {
			alert(res.data);
			return null;
		}
	} catch (err) {
		alert("something went wrong, please try again");
	}
};
