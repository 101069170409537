import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';

import Card from "@material-ui/core/Card";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

import { API_ENDPOINTS } from "../../../APIUrls.js";
import axios from "axios";

import { RESULT_TO_PASS_STATUS } from "../../../runResultTypesMapping.js";
import { RESULTS_PANE_TABS } from "./ResultsPane.js";

import { API_SubmitCode, API_RunCode, API_RunCode_CustomInput, API_PreviewSubmitCode } from "./APIHelpers.js";
import { UpdateSubmitTerminalSelection } from "./ResultsPane"

function RunCode() {

    return async (dispatch, getState) => {

        //getting required state 
        const QID =
            getState().Code.Contest["CurrentQuestionID"];
        const language =
            getState().Code.Contest[QID]["Language"];
        const code =
            getState().Code.Contest[QID][language];
        const input =
            getState().Code.Contest[QID]["CustomInput"];
        const customInputToggle =
            getState().Code.CodeUI["CustomInputCheckBox"];

        if (customInputToggle) {
            //if you are running against custom input
            const data = {
                "custom_input": input,
                "language": language,
                "code": code,
            };

            const action = {
                "type": "CODE/RESULTS_SECTION_CUSTOM_RUN_TAB_LOADING",
                "payload": true,
            };

            dispatch(action)
            dispatch(UpdateSubmitTerminalSelection(RESULTS_PANE_TABS.TERMINAL_PANE))

            const payload = {
                "QuestionID": QID,
                "TerminalOutput": await API_RunCode_CustomInput(data),
                "ResultsSectionTabSelection": RESULTS_PANE_TABS.TERMINAL_PANE,
            };

            action.payload = false
            dispatch(action)

            action.type = "CODE/RUN_CODE_CUSTOM_INPUT";
            action.payload = payload;

            dispatch(action);

        } else {
            const data = {
                "question_id": QID,
                "language": language,
                "code": code,
            };

            const action = {
                "type": "CODE/RESULTS_SECTION_RUN_TAB_LOADING",
                "payload": true,
            };

            dispatch(action)
            dispatch(UpdateSubmitTerminalSelection(RESULTS_PANE_TABS.SAMPLE_TESTS_PANE))

            const payload = {
                "QuestionID": QID,
                "SampleResults": await API_RunCode(data),
                "ResultsSectionTabSelection": RESULTS_PANE_TABS.SAMPLE_TESTS_PANE,
            }

            action.payload = false
            dispatch(action)

            action.type = "CODE/RUN_CODE";
            action.payload = payload;

            dispatch(action);
        }

    };

}

function SubmitCode(questionType) {
    return async (dispatch, getState) => {

        const QID =
            getState().Code.Contest["CurrentQuestionID"];
        const language =
            getState().Code.Contest[QID]["Language"];
        const code =
            getState().Code.Contest[QID][language];

        const data = {
            "question_id": QID,
            "language": language,
            "code": code,
        };

        if (questionType.isTest) {
            const contestID =
                getState().Code.ContestInfo.ContestID;
            data["contest_id"] = contestID;
        }

        const action = {
            "type": "CODE/RESULTS_SECTION_SUBMIT_TAB_LOADING",
            "payload": true,
        };


        dispatch(action)
        dispatch(UpdateSubmitTerminalSelection(RESULTS_PANE_TABS.SUBMIT_RESULTS_PANE))

        const payload = {
            "QuestionID": QID,
            "ResultsSectionTabSelection": RESULTS_PANE_TABS.SUBMIT_RESULTS_PANE
        };

        if(questionType.isTest){
            payload["SubmitResults"] = await API_SubmitCode(data);
        }
        else{
            payload["SubmitResults"] = await API_PreviewSubmitCode(data);
        }

        action.payload = false
        dispatch(action)

        action.type = "CODE/SUBMIT_CODE";
        action.payload = payload;

        dispatch(action);

    };

}

function ToggleCustomInput(customInput) {
    return (dispatch) => {

        const action = {
            "type": "CODE/TOGGLE_CUSTOM_INPUT_CHECK_BOX",
            "payload": customInput,
        };

        dispatch(action);
    };
}

function UpdateCustomInput(value) {

    return (dispatch, getState) => {

        const QID =
            getState().Code.Contest["CurrentQuestionID"];

        const action = {
            "type": "CODE/UPDATE_CUSTOM_INPUT",
            "payload": {
                "QuestionID": QID,
                "CustomInput": value,
            },
        };

        dispatch(action);
    };
}

//JSX returning functions

function RunButton() {
    const dispatch = useDispatch();
    const runloading = useSelector(
        state => state.Code.CodeUI.RunLoading
    );
    const customRunloading = useSelector(
        state => state.Code.CodeUI.CustomRunLoading
    );
    return (
        <Button
            disabled={runloading || customRunloading}
            style={{ margin: "5px" }}
            variant="contained"
            onClick={() => {
                dispatch(RunCode());
            }}
        >
            Run Code
        </Button>
    );

}

function SubmitButton(props) {
    const dispatch = useDispatch();
    const loading = useSelector(
        state => state.Code.CodeUI.SubmitLoading
    );
    return (
        <Button
            disabled={loading}
            style={{ margin: "5px" }}
            variant="contained"
            color="primary"
            onClick={() => {
                dispatch(SubmitCode(props.questionType));
            }}

        >
            Submit Code
        </Button>
    );
}

function CustomInputCheckBox() {

    const customInputChecked = useSelector(
        state => state.Code.CodeUI.CustomInputCheckBox
    );
    const dispatch = useDispatch();

    return (
        <FormControlLabel
            value="end"
            control={
                <Checkbox
                    checked={customInputChecked}
                    onChange={(event) => {
                        dispatch(ToggleCustomInput(event.target.checked));
                    }}
                    name="custom"
                    color="primary"
                />
            }
            label="Test against custom input"
            labelPlacement="end"
        />
    );

}

function CustomInput() {

    const QID = useSelector(state => state.Code.Contest["CurrentQuestionID"]);

    const customInputChecked = useSelector(
        state => state.Code.CodeUI.CustomInputCheckBox
    );
    const customInputValue = useSelector(
        state => state.Code.Contest[QID].CustomInput
    );

    const dispatch = useDispatch();

    if (customInputChecked) {
        return (
            <textarea
                style={{ marginLeft: "10px", marginBottom: "8px" }}
                rows="4"
                cols="30"
                value={customInputValue}
                onChange={(event) => dispatch(UpdateCustomInput(event.target.value))}
            ></textarea>
        );
    }
    else {
        return (
            <div></div>
        );
    }

}

export function SubmitPane(props) {


    return (
        <Card id="SubmitPane" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div
                style={{
                    margin: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <CustomInputCheckBox />
                <div>
                    <RunButton />
                    <SubmitButton questionType={props.questionType} />
                </div>
            </div>
            <div>
                <CustomInput />
            </div>
        </Card>


    );

}
