import ContestInfoReducer from "./ContestInfo";
import ContestReducer from "./Contest";
import CodeUIReducer from "./UI";
import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const TempCodeReducer = combineReducers({
   "ContestInfo": ContestInfoReducer,
   "Contest": ContestReducer,
   "CodeUI": CodeUIReducer,     
})


const PersistConfig = {
    key: "Code",
    storage: storage,
    whitelist: ["Contest"],
};

const CodeReducer = persistReducer(PersistConfig, TempCodeReducer);

export default CodeReducer;
