import NavBar from "../components/NavBar";
import ResultForm from "../components/ResultsPage/ResultForm";


export default function Result() {
    return (
        <div>
            <NavBar/>
            <ResultForm/>
        </div>
    )
}