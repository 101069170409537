import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "bootstrap/dist/css/bootstrap.min.css";

import { store, persistor } from "./store";
import LandingPage from "./routes/LandingPage";
import HomePage from "./routes/HomePage";
import CodingTestPage from "./routes/CodingTestPage";
import AptitudeTestPage from "./routes/AptitudeTestPage";
import AptitudeTestDetailsPage from "./routes/AptitudeTestDetailsPage";
import SetPasswordPage from "./routes/SetPasswordPage";
import ChangePasswordPage from "./routes/ChangePasswordPage";
import ProfilePage from "./routes/ProfilePage";
import CodingTestDetailsPage from "./routes/CodingTestDetailsPage";
import CombinedTestsDetailsPage from "./routes/CombinedTestsDetailsPage";
import AuthenticatedRoute from "./components/HigherOrderComponents/AuthenticatedRoute";
import McqPreviewPage from "./routes/McqPreviewPage";
import CodingQuestionPreviewPage from "./routes/CodingQuestionPreviewPage.js";
import Result from "./routes/Result";
import Faq from "./routes/Faq.js";

import Page404 from "./routes/404.js";
import Page403 from "./routes/403.js";

const App = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<Switch>
					<Route exact path="/" component={LandingPage} />
					<Route exact path="/set_password/:uid/:token" component={SetPasswordPage} />
					<AuthenticatedRoute exact path="/home" component={HomePage} />
					{/* <AuthenticatedRoute exact path="/faq" component={Faq} /> */}
					<AuthenticatedRoute exact path="/change_password" component={ChangePasswordPage} />
					<AuthenticatedRoute exact path="/profile/:username" component={ProfilePage} />
					<AuthenticatedRoute exact path="/aptitude/" component={AptitudeTestPage} />
					<AuthenticatedRoute exact path="/aptitude/details/:pk" component={AptitudeTestDetailsPage} />
					<AuthenticatedRoute exact path="/coding/test/details" component={CodingTestDetailsPage} />
					<AuthenticatedRoute exact path="/coding/test/attempt" component={CodingTestPage} />
					<AuthenticatedRoute exact path="/exam/:pk" component={CombinedTestsDetailsPage} />
					<AuthenticatedRoute exact path="/aptitude/preview/:pk" component={McqPreviewPage} />
					<AuthenticatedRoute exact path="/coding/preview/:pk" component={CodingQuestionPreviewPage} />
					<AuthenticatedRoute exact path="/result" component={Result}  />
					<AuthenticatedRoute exact path="/forbidden" component={Page403} status={403} />
					<AuthenticatedRoute exact path="/notfound" component={Page404} status={404} />
					<AuthenticatedRoute exact path="*" component={Page404} status={404} />
				</Switch>
			</Router>
		</PersistGate>
	</Provider>
);

export default App;
