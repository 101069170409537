import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Image } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import { useSelector, useDispatch } from "react-redux";

export default function CodingQuestionPane({}) {
	const QuestionID = useSelector((state) => state.Code.Contest["CurrentQuestionID"]);
	const LatexText = useSelector((state) => state.Code.Contest[QuestionID]["LatexText"]);
	const questionImage = useSelector((state) => state.Code.Contest[QuestionID]["QuestionImage"]);

	return (
		<div style={{ height: "100%" }}>
			<Card
				style={{
					margin: "10px",
					height: "calc(100% - 20px)",
					overflowY: "auto",
				}}
			>
				<CardContent>
					<ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
						{LatexText}
					</ReactMarkdown>
					<br />
					{questionImage != null && (
						<Image
							src={questionImage}
							style={{ width: "100%", maxWidth: "40rem",maxHeight:"40rem","object-fit":"contain" }}
						/>
					)}
				</CardContent>
			</Card>
		</div>
	);
}
