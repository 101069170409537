import React, { useState } from "react";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useStyles } from "./styles";
import { register } from "../../actions/auth";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://material-ui.com/">
				JLR CodeRace
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

function SignUpGrid({ setSideGrid, register }) {
	const classes = useStyles();

	const initialFormData = Object.freeze({
		email: "",
		username: "",
		first_name: "",
		last_name: "",
	});
	const [formData, setFormData] = useState(initialFormData);

	const initialHelperTexts = Object.freeze({
		email: "",
		username: "",
		first_name: "",
		last_name: "",
	});
	const [helperTexts, setHelperTexts] = useState(initialHelperTexts);

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
	};

	const [SignUpPressed, UpdateSignUpPressed] = useState(false);
	const JSXcircularspinner = (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<CircularProgress size="1.5rem" />
		</div>
	);
	const JSXSignUpButton = SignUpPressed ? JSXcircularspinner : "Sign Up";

	const handleSubmit = async (e) => {
		e.preventDefault();
		UpdateSignUpPressed(true);
		await register(formData, setHelperTexts, helperTexts);
		UpdateSignUpPressed(false);
	};

	return (
		<Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign up
				</Typography>
				<form className={classes.form} noValidate>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="first_name"
								label="First Name"
								helperText={helperTexts["first_name"]}
								name="first_name"
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="last_name"
								label="Last Name"
								helperText={helperTexts["last_name"]}
								name="last_name"
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								autoComplete="fname"
								name="username"
								variant="outlined"
								required
								fullWidth
								id="username"
								label="Username"
								helperText={helperTexts["username"]}
								autoFocus
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="email"
								label="Email Address"
								helperText={helperTexts["email"]}
								name="email"
								autoComplete="email"
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={handleSubmit}
						disabled={SignUpPressed}
					>
						{JSXSignUpButton}
					</Button>
					<Grid container justify="flex-end">
						<Grid item>
							<Link
								href="#"
								variant="body2"
								onClick={(e) => {
									e.preventDefault();
									setSideGrid(0);
								}}
							>
								Already have an account? Sign in
							</Link>
						</Grid>
					</Grid>
				</form>
			</div>
			<Box mt={5}>
				<Copyright />
			</Box>
		</Grid>
	);
}

export default connect(null, { register })(SignUpGrid);
