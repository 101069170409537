function APIError(status){
    this.httpStatus=status;
    this.toString = function(){ return this.httpStatus.toString() };
}

function APIErrorBasedRedirect(httpStatus, history){
    switch(httpStatus){
        case 404: // Not found, the page was not found
            history.push("/notfound");
            break;
        case 403: //Forbidden, you do not have access to this page
            history.push("/forbidden");
            break;
        default:
            history.push("/home");
    }
}


export {APIErrorBasedRedirect};

export default APIError;
