import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useStore, useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SplitPane from "react-split-pane";

import CodingTestBar from "../components/CodingPreviewAndTestPage/CodingTestBar";
import CodingQuestionPane from "../components/CodingPreviewAndTestPage/CodingQuestionPane";
import CodingConsolePane from "../components/CodingPreviewAndTestPage/CodingConsolePane";


import ContestHeader from "../components/ContestHeader";
import { ReactReduxContext } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API_GetTimeRemaining, API_GetContestQuestions, API_GetContestState } from "./APIHelpers.js";

function FetchTimeRemaining() {
	return async (dispatch, getState) => {
		const ContestID = getState().Code.ContestInfo["ContestID"];
		const timeRemaining = await API_GetTimeRemaining(ContestID);
		//console.log(timeRemaining);
		const action = {
			type: "CODE/UPDATE_TIME_REMAINING",
			payload: timeRemaining,
		};
		dispatch(action);

		return Promise.resolve();
	};
}

function FetchCodingTestDetails() {
	return async (dispatch, getState) => {
		const ContestID = getState().Code.ContestInfo["ContestID"];

		const ContestStateSlice = await API_GetContestQuestions(ContestID);
		const action = {
			type: "CODE/START_CONTEST_ATTEMPT",
			payload: ContestStateSlice,
		};
		dispatch(action);
		return Promise.resolve();
	};
}

function SetCodingPageLoadingStatus(Status) {
	return (dispatch) => {
		const action = {
			type: "CODE/UPDATE_PAGE_LOADING_STATUS",
			payload: Status,
		};
		dispatch(action);
	};
}

function CheckStateIntegrity(Code) {
	if (Code.Contest["CurrentContestID"] === Code.ContestInfo["ContestID"]) {
		return true;
	} else {
		return false;
	}
}

function ResetContestInfo() {
	return (dispatch) => {
		const action = {
			type: "CODE/RESET_CONTEST_INFO",
		};
		dispatch(action);
	};
}

function CodingTestPage({}) {
	useEffect(() => {
		document.title = "Coding Page";

	});

	const state_ref = useStore().getState().Code;
	const dispatch = useDispatch();
	const history = useHistory();

	//Loading related code for the whole page
	const LoadingStatus = useSelector((state) => state.Code.CodeUI["isPageLoading"]);
	useEffect(() => {
		// we need to make sure that Loading status is true
		dispatch(SetCodingPageLoadingStatus(true));
		//is the following cleanup function necessary?
		return () => {
			dispatch(SetCodingPageLoadingStatus(true));
		};
	}, []);
	//useEffect is called in the same order as  in the code.
	useEffect(() => {
		const StartContest = async function (dispatch) {
			await dispatch(FetchCodingTestDetails());
			await dispatch(FetchTimeRemaining());
			dispatch(SetCodingPageLoadingStatus(false));
		};
		const ResumeContest = async function (dispatch) {
			await dispatch(FetchTimeRemaining());
			dispatch(SetCodingPageLoadingStatus(false));
		};
		//console.log(history);
		if (state_ref.ContestInfo["ContestID"] === -1) {
			history.push("/");
		} else if (!CheckStateIntegrity(state_ref)) {
			StartContest(dispatch);
		} else {
			ResumeContest(dispatch);
		}
	}, []);
	//Finished with loading related code

	if (!LoadingStatus) {
		return (
			<div>
				<div className="App">
					<ContestHeader />
					<CodingTestBar />

					<SplitPane
						split="vertical"
						defaultSize="60vw"
						maxSize={1000}
						minSize={550}
						primary="second"
						style={{ display: "flex", height: "calc(100% - 28.5mm)" }}
					>
						<CodingQuestionPane />
						<CodingConsolePane questionType = {{isTest: true}}/>
					</SplitPane>
				</div>
			</div>
		);
	} else {
		return (
			<div
				style={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {})(CodingTestPage);
