import axios from "axios";
import { TEST_MANAGER } from "./types";
import { store } from "../store";
import { useSelector } from "react-redux";
import {API_ReportError} from "../ReportError"

export const getMcqTestDetails = (pk) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Token ${localStorage.getItem("token")}`,
			Accept: "application/json",
		},
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mcq/details/${pk}`, config);
		dispatch({
			type: TEST_MANAGER.GET_MCQ_DETAILS_SUCCESS,
			payload: res.data,
		});
		return res.data.pk;
	} catch (err) {
		dispatch({
			type: TEST_MANAGER.GET_MCQ_DETAILS_FAIL,
		});
		console.error(err)
		API_ReportError(err);
		return err.response.data.detail;
	}
};

export const getTestRunner = () => async (dispatch,getState) => {
        const pk = getState().mcq.test_details.pk;
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Token ${localStorage.getItem("token")}`,
			Accept: "application/json",
		},
	};

	try {
		const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mcq/${pk}`, config);
		dispatch({
			type: TEST_MANAGER.GET_SUCCESS,
			payload: res.data,
		});
		return res.data.message;
	} catch (err) {
		console.error(err)
		API_ReportError(err);
		dispatch({
			type: TEST_MANAGER.GET_FAILED,
		});
	}
};

export const postMcqTestEvent = (msg) => async (dispatch, getState) => {
	let pk = getState().mcq.test_details.pk;

	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Token ${localStorage.getItem("token")}`,
			Accept: "application/json",
		},
	};

	const body = JSON.stringify(msg);

	try {
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/mcq/${pk}`, body, config);

		dispatch({
			type: TEST_MANAGER.GET_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		console.error(err)
		API_ReportError(err);
		dispatch({
			type: TEST_MANAGER.GET_FAILED,
		});
	}
};
