import axios from "axios";
import { USER_PROFILE } from "./types";
import {API_ReportError} from "../ReportError"

export const update_profile = (body) => async (dispatch) => {
    if (localStorage.getItem("token")) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        };

        try {
            const res = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/profile/`, body, config);
            if (res.status === 200) {
                dispatch({
                    type: USER_PROFILE.POST_PROFILE_SUCCESS,
                    payload: res.data,
                });
                alert("Profile updated");
            } else {
                alert("something went wrong, please try again");
            }
        } catch (err) {
            // alert(err.response.data);
        }
    } else {
        dispatch({ type: "USER_LOADED_FAIL" });
        // logout and nuke store
    }
};

export const load_user_profile = () => async (dispatch) => {
    if (localStorage.getItem("token")) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/profile/`, config);
            dispatch({ type: USER_PROFILE.GET_PROFILE_SUCCESS, payload: res.data });
        } catch (err) {
            // alert(err.response.data);
            console.error(err.response);
            API_ReportError(err);
        }
    } else {
        dispatch({ type: "USER_LOADED_FAIL" });
        // logout and nuke store
    }
};

export const load_user_recruitment_profile = () => async (dispatch) => {
    if (localStorage.getItem("token")) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/profile/recruit/`, config);
            dispatch({ type: USER_PROFILE.GET_RECRUITMENT_PROFILE_SUCCESS, payload: res.data });
        } catch (err) {
            // alert(err.response.data);
            console.error(err.response);
            API_ReportError(err);
        }
    } else {
        dispatch({ type: "USER_LOADED_FAIL" });
        // logout and nuke store
    }
};

export const update_user_recruitment_profile = (body, formErrorData, setFormErrorData) => async (dispatch) => {
    if (localStorage.getItem("token")) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        };
        
        /*Quick and dirty logic to ignore college entrance metric if college is other*/
        if(body["college_name"]==="others"){
            body["college_entrance_metric"] = -1;
        }
        /*End of dirty logic*/

        try {
            const res = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/profile/recruit/`, body, config);
            if (res.status === 200) {
                dispatch({
                    type: USER_PROFILE.POST_RECRUITMENT_PROFILE_SUCCESS,
                    payload: res.data,
                });
                alert("Recruitment Details updated");
                setFormErrorData({});
            } else {
                alert("something went wrong, please try again");
            }
        } catch (err) {
            setFormErrorData(err.response.data);
        }
    } else {
        dispatch({ type: "USER_LOADED_FAIL" });
        // logout and nuke store
    }
};
