import axios from "axios";
import {API_ENDPOINTS} from "../APIUrls.js";
import { LANGUAGES, MIME } from "../langlist.js";
import {RESULT_TO_PASS_STATUS} from "../runResultTypesMapping.js";

import APIError from "../rootAPIHelpers.js";
import {API_ReportError} from "../ReportError"

async function API_GetContestList(data = {}){

    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    const axiosConfig = {
        url: API_ENDPOINTS.GETCODINGCONTESTLIST,
        method: "get",
        headers: headers,
    };

    try {
        const response = await axios( axiosConfig );
        var ContestList = response.data;


    }catch(error){
        console.error(error);
        API_ReportError(error);
        var ContestList = [];
    }

    return ContestList;
}

async function API_GetContestTimings(ContestID){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    const axiosConfig = {
        url: `${API_ENDPOINTS.GETCODINGCONTESTDETAILS}${ContestID}/`,
        method: "get",
        headers: headers,
    };

    try {
        const response = await axios(axiosConfig);
        var Time = {
            "start": response.data["starttime"]+" "+response.data["startdate"],
            "end": response.data["endtime"]+" "+response.data["enddate"],
        };
    }catch(error){
        console.error(error);
        API_ReportError(error);
        var Time = {
            "start":"ERROR",
            "end":"ERROR",
        };
    }

    return Time;
}

async function API_GetPreviewQuestion( QuestionID){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    const axiosConfig = {
        url: `${API_ENDPOINTS.GETQUESTIONPREVIEW}${QuestionID}/`,
        method: "get",
        headers: headers,
    };

    try{
        const response = await axios(axiosConfig);
        const QuestionDict = {};
        const QuestionIDList = [];
        for ( const Question of response.data) {
            
            const SampleTestCases = Question["sampletestcases"].map(
                (element, index) => {
                    return {
                        "name": "Sample Test Case " +index.toString(),
                        "input": element["input"],
                        "output": element["output"],
                    };
                }
            );

            const DummySampleResults = Question["sampletestcases"].map(
                (element,index) => {
                    return {
                        "pass_status": RESULT_TO_PASS_STATUS.UNKNOWN,
                        "output":"?",
                    };
                }
            );

            const newQuestion = {
                "ID": Question["id"],
                "LatexText": Question["text"],
                "Title": Question["title"],
                "Language": LANGUAGES[0],
                "Mime": MIME[LANGUAGES[0]],
                "SampleTestCases": SampleTestCases,
                "SampleResults": DummySampleResults,
                "TerminalOutput": "",
                "SubmitResults":[],
                "CustomInput":"", 
                "QuestionImage":Question["question_image"],
            };

            for( const lang of LANGUAGES){
                newQuestion[ lang ] = "";
            }
            for ( const [lang, startercode] of  Object.entries(Question["startercodes"]) ){
                //console.log(lang, startercode)
                newQuestion[lang] = startercode
            }

            QuestionIDList.push(newQuestion["ID"]);
            QuestionDict[newQuestion["ID"]] = newQuestion;
        }
        
        var output = {
            "CurrentContestID": -2,
            "CurrentQuestionID": QuestionIDList[0],
            "ContestQuestionIDList": QuestionIDList,
            ...QuestionDict,

        }

    }catch(error){
        console.error(error);
        API_ReportError(error);
        //var output = {};
        throw new APIError(error.response.status);
    }

    //console.log(output)
    return output;
}

async function API_GetContestQuestions( ContestID){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    const axiosConfig = {
        url: `${API_ENDPOINTS.GETCONTESTQUESTIONS}${ContestID}/`,
        method: "get",
        headers: headers,
    };

    try{
        const response = await axios(axiosConfig);
        const QuestionDict = {};
        const QuestionIDList = [];
        for ( const Question of response.data) {
            
            const SampleTestCases = Question["sampletestcases"].map(
                (element, index) => {
                    return {
                        "name": "Sample Test Case " +index.toString(),
                        "input": element["input"],
                        "output": element["output"],
                    };
                }
            );

            const DummySampleResults = Question["sampletestcases"].map(
                (element,index) => {
                    return {
                        "pass_status": RESULT_TO_PASS_STATUS.UNKNOWN,
                        "output":"?",
                    };
                }
            );

            const newQuestion = {
                "ID": Question["id"],
                "LatexText": Question["text"],
                "Title": Question["title"],
                "Language": LANGUAGES[0],
                "Mime": MIME[LANGUAGES[0]],
                "SampleTestCases": SampleTestCases,
                "SampleResults": DummySampleResults,
                "TerminalOutput": "",
                "SubmitResults":[],
                "CustomInput":"", 
                "QuestionImage":Question["question_image"],
            };

            for( const lang of LANGUAGES){
                newQuestion[ lang ] = "";
            }
            for ( const [lang, startercode] of  Object.entries(Question["startercodes"]) ){
                //console.log(lang, startercode)
                newQuestion[lang] = startercode
            }

            QuestionIDList.push(newQuestion["ID"]);
            QuestionDict[newQuestion["ID"]] = newQuestion;
        }
        
        var output = {
            "CurrentContestID": ContestID,
            "CurrentQuestionID": QuestionIDList[0],
            "ContestQuestionIDList": QuestionIDList,
            ...QuestionDict,

        }
    }catch(error){
        console.error(error);
        API_ReportError(error);
        var output = {};
    }

    //console.log(output)
    return output;
}

async function API_StartContest(ContestID){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    const axiosConfig = {
        url: `${API_ENDPOINTS.STARTCODINGCONTEST}${ContestID}/`,
        method: "post",
        headers: headers,
    };

    try{
        const response = await axios(axiosConfig);
        console.log(response.data);
    }catch(error){
        console.error(error);
        API_ReportError(error);
    }


}

async function API_GetContestState(ContestID){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    const axiosConfig = {
        url: `${API_ENDPOINTS.GETCODINGCONTESTDETAILS}${ContestID}/`,
        method: "get",
        headers: headers,
    };

    try {
        var State = {};
        const response = await axios(axiosConfig);
        State = {
            "ContestLive": response.data["isActive"],
            "isStarted": response.data["isStarted"],
            "isFinished": response.data["isFinished"],
            "name": response.data["name"],
            "instructions": response.data["instructions"],
            "duration": response.data["duration"],
            "totalQuestions": response.data["totalQuestions"]
        };
    }catch(error){
        console.error(error);
        API_ReportError(error);
    }

    return State;
}

async function API_GetTimeRemaining(ContestID){
    const token = localStorage.getItem('token');
    const headers = {
        "Authorization":`Token ${token}`,
    };
    
    const axiosConfig = {
        url: `${API_ENDPOINTS.GETCODINGCONTESTTIMEREMAINING}${ContestID}/`,
        method: "get",
        headers: headers,
    };

    try{
        var response = await axios( axiosConfig );

    }catch(error){
        console.error(error);
        API_ReportError(error);
    }

    return parseFloat(response.data);

}

export {API_GetPreviewQuestion, API_GetContestQuestions, API_GetContestList, API_GetContestState, API_GetTimeRemaining};
