import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Link, useParams } from "react-router-dom";
import { update_profile, load_user_profile } from "../actions/userProfile";

import {
	Button,
	Container,
	Row,
	Col,
	Media,
	Card,
	ListGroup,
	ListGroupItem,
	Accordion,
	Image,
	Jumbotron,
	InputGroup,
	FormControl,
	Alert,
} from "react-bootstrap";
import { BsBuilding } from "react-icons/bs";
import { AiFillCamera } from "react-icons/ai";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import NavBar from "../components/NavBar";
import RecruitmentForm from "../components/ProfilePage/RecruitmentForm";
import styles from "../styles/ProfilePage/ProfilePage";
import "../styles/ProfilePage/ProfilePage.css";

function ProfilePage({ update_profile, load_user_profile }) {
	const { username } = useParams();

	// runs only on mount
	useEffect(() => {
		load_user_profile();
	}, []);

	// subscribe to store to get relevant data
	
	let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	let user = useSelector((state) => state.auth.user);
	let simple_user_profile = useSelector((state) => state.user_profile.simple_profile);
	let dp = simple_user_profile.profile_picture;
	let bio = simple_user_profile.bio;
	let location = simple_user_profile.location;
	if (dp !== null) {
		dp = dp;
	} else {
		dp = "../../logo512.png";
	}
	let recruitment_user_profile = useSelector((state) => state.user_profile.recruitment_profile);
	let fill_form_prompt = recruitment_user_profile === "user recruitment profile does not exist";
	// @Todo
	// check if username is logged in user
	// if not, check if username exists
	// display view only page
	// otherwise display user doesnt exist

	// function to handle dp change
	function selectImage(e) {
		e.preventDefault();
		const data = new FormData();
		data.append("profile_picture", e.target.files[0]);
		update_profile(data);
	}

	// functions to handle bio and location changes
	const [editState, setEditState] = useState(false);
	const initialFormData = Object.freeze({
		bio: bio,
		location: location,
	});
	const [formData, setFormData] = useState(initialFormData);
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		update_profile(formData);
		setEditState(false);
	};
	if(user)
	{
	return (
		<div>
			<NavBar />
			<br />
			<div style={styles.profileContainer}>
				<Container style={styles.leftContainer}>
					<Container>
						<Row>
							<Paper className="d-block mx-auto dp-container">
								<Image style={styles.dp} src={dp} />
							</Paper>
						</Row>
						<Row className="justify-content-center">
							<label htmlFor="select-dp">
								<AiFillCamera />
							</label>
							<input
								type="file"
								accept="image/*"
								id="select-dp"
								style={{ display: "none" }}
								onChange={selectImage}
							/>
						</Row>

						<Row className="mx-auto" style={styles.fullName}>
							{user.first_name} {user.last_name}
						</Row>

						{!editState ? (
							<div>
								<Row className="mx-auto" style={styles.bio}>
									{bio}
								</Row>
								{location !== "" ? (
									<Row className="mx-auto" style={{ marginBottom: "4px" }}>
										<BsBuilding style={{ marginTop: "4px", marginRight: "8px" }} /> {location}
									</Row>
								) : (
									<div></div>
								)}
								<Row>
									<Button className="mx-auto" onClick={() => setEditState(true)}>
										Edit Profile
									</Button>
								</Row>
							</div>
						) : (
							<div>
								<Row style={{ marginBottom: "4px" }}>
									<InputGroup>
										<FormControl name="bio" defaultValue={bio} onChange={handleChange} />
									</InputGroup>
								</Row>

								<Row style={{ marginBottom: "4px" }}>
									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>
												<BsBuilding />
											</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl defaultValue={location} name="location" onChange={handleChange} />
									</InputGroup>
								</Row>
								<Row>
									<Button variant="danger" className="mx-auto" onClick={() => setEditState(false)}>
										Cancel
									</Button>
									<Button className="mx-auto" onClick={handleSubmit}>
										Save
									</Button>
								</Row>
							</div>
						)}
					</Container>
				</Container>

				<Container style={styles.rightContainer}>
					<Accordion defaultActiveKey="0">
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
									<Row>
										<Col>Recruitment Details Form</Col>
										{fill_form_prompt ? (
											<Col style={{ color: "red" }} className="row justify-content-center">
												Filling this form accurately is necessary to apply for recruitment!!
											</Col>
										) : (
											<div></div>
										)}
									</Row>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="0">
								<Card.Body style={{ backgroundColor: "#cdd5dc" }}>
									<RecruitmentForm />
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
				</Container>
			</div>
		</div>
	);}
	else
	return (
		<div
			style={{
				height: "20vh",
				width: "100vw",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<CircularProgress />
		</div>)
}

export default connect(null, { update_profile, load_user_profile })(ProfilePage);
