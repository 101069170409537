import React from "react";
import { Redirect,useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { check_authenticated, nuke_store } from "../actions/auth";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import SignInGrid from "../components/LandingPage/SignInGrid";
import SignUpGrid from "../components/LandingPage/SignUpGrid";
import ForgotPasswordGrid from "../components/LandingPage/ForgotPasswordGrid";
import LandingGrid from "../components/LandingPage/LandingGrid";
import { useStyles } from "../components/LandingPage/styles";

function LandingPage({ check_authenticated, nuke_store }) {
	const classes = useStyles();
	let location = useLocation();

	const [sideGridNum, setSideGridNum] = React.useState(0);

	function setSideGrid(sideGridNum, setSideGridNum) {
		if (sideGridNum === 0) {
			return <SignInGrid setSideGrid={setSideGridNum} />;
		} else if (sideGridNum === 1) {
			return <SignUpGrid setSideGrid={setSideGridNum} />;
		} else {
			return <ForgotPasswordGrid setSideGrid={setSideGridNum} />;
		}
	}

	let sideGrid = setSideGrid(sideGridNum, setSideGridNum);

	check_authenticated();
	let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	if (isAuthenticated) {
		if(location.state)
			return <Redirect to={location.state.from} />;
		else
			return <Redirect to="/home" />;
	} else {
		// clear store
		nuke_store();
	}
	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<LandingGrid />
			{sideGrid}
		</Grid>
	);
}

export default connect(null, { check_authenticated, nuke_store })(LandingPage);
