import { TEST_MANAGER } from "../actions/types";

const initialState = {
	test_details: {
		pk: null,
		test_name: null,
		test_type: null,
		choice_randomization: null,
		question_randomization: null,
		max_time: null,
		max_questions: null,
		instructions: "",
	},
	test_state: {
		test_end: true,
	},
	question: null,
};

export default function updateTestManagerState(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case TEST_MANAGER.GET_SUCCESS:
			return {
				...state,
				test_details: payload.test_details,
				test_state: payload.test_state,
				question: payload.question,
			};

		case TEST_MANAGER.GET_MCQ_DETAILS_SUCCESS:
			return {
				...state,
				test_details: payload,
			};

		case TEST_MANAGER.END_MCQ_TEST:
			return {
				...state,
				test_state: {
					...state.test_state,
					test_end: true,
				},
			};

		case TEST_MANAGER.GET_MCQ_DETAILS_FAIL:
			return state;
		
		case TEST_MANAGER.GET_FAILED:
			return state;
			
		case "NUKE_STORE":
			return initialState;

		default:
			return state;
	}
}
