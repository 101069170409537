import { EXAM_MANAGER } from "../actions/types";

const initialState = {
	live_exams_list: [],
	current_exam_manager: {
		exam_details: {},
		exam_sections: [],
		exam_runner: {},
	},
	exam_list:[],
};

export default function ExamReducer(state = initialState, action) {
	switch (action.type) {
		case EXAM_MANAGER.GET_LIVE_EXAMS_SUCCESS:
			return {
				...state,
				live_exams_list: action.payload,
			};

		case EXAM_MANAGER.GET_LIVE_EXAMS_FAIL:
			return {
				...state,
				live_exams_list: [],
			};

		case EXAM_MANAGER.GET_EXAM_SECTIONS_STATE_SUCCESS:
			return {
				...state,
				current_exam_manager: {
					...state.current_exam_manager,
					exam_sections: action.payload,
				},
			};

		case EXAM_MANAGER.GET_EXAM_SECTIONS_STATE_FAIL:
			return {
				...state,
				current_exam_manager: {
					...state.current_exam_manager,
					exam_sections: [],
				},
			};

		case EXAM_MANAGER.SET_CURRENT_EXAM_STATE:
			return {
				...state,
				current_exam_manager: {
					...state.current_exam_manager,
					exam_details: action.payload,
				},
			};

		case EXAM_MANAGER.UPDATE_CURRENT_EXAM_RUNNER_SUCCESS:
			return {
				...state,
				current_exam_manager: {
					...state.current_exam_manager,
					exam_runner: action.payload,
				},
			};

		case EXAM_MANAGER.GET_CURRENT_EXAM_RUNNER_SUCCESS:
			return {
				...state,
				current_exam_manager: {
					...state.current_exam_manager,
					exam_runner: action.payload,
				},
			};

		case EXAM_MANAGER.REMOVE_CURRENT_EXAM_INFORMATION:
			return {
				...state,
				current_exam_manager: {
					...state.current_exam_manager,
					exam_details: {},
					exam_sections: [],
					exam_runner: {},
				},
			};

		case EXAM_MANAGER.GET_EXAM_LIST_REQUEST:
			return {...state,loading:true,exam_list:[],error:null};

		case EXAM_MANAGER.GET_EXAM_LIST_SUCCESS:
			return {loading:false,...state,loading:false,exam_list:action.payload,error:null};
		
		case EXAM_MANAGER.GET_EXAM_LIST_FAIL:
			return {...state,loading:false,error : action.payload};
		
		case "NUKE_STORE":
			return initialState;

		default:
			return state;
	}
}
