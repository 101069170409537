import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import CircularProgress from "@material-ui/core/CircularProgress";
import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you

export default function AptitudeQuestionPane() {
	let questionText = useSelector((state) => state.mcq.question.question_text);
	let questionImage = useSelector((state) => state.mcq.question.question_image);

	const [loaded, setLoaded] = useState(false);
	useEffect(() => {
		setLoaded(false);
	}, [questionText]);

	const JSXcircularspinner = (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<CircularProgress size="1.5rem" />
		</div>
	);

	return (
		<div style={{ height: "100%" }}>
			<Card
				style={{
					margin: "10px",
					height: "calc(100% - 20px)",
					overflowY: "auto",
				}}
			>
				<CardContent>
					<ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
						{questionText}
					</ReactMarkdown>
					<br />
					<Image
						src={questionImage}
						style={
							loaded
								? { width: "100%", maxWidth: "40rem", maxHeight: "40rem", "object-fit": "contain" }
								: { display: "none" }
						}
						onLoad={() => setLoaded(true)}
					/>
					{loaded | (questionImage === null) ? <div></div> : JSXcircularspinner}
				</CardContent>
			</Card>
		</div>
	);
}
