import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import SplitPane from "react-split-pane";
import { Alert, Container } from "react-bootstrap";

import CircularProgress from "@material-ui/core/CircularProgress";

import NavBar from "../components/NavBar";
import ContestHeader from "../components/ContestHeader";
import AptitudeTestBar from "../components/AptitudeTestPage/AptitudeTestBar";
import AptitudeQuestionPane from "../components/AptitudeTestPage/AptitudeQuestionPane";
import AptitudeChoicesPane from "../components/AptitudeTestPage/AptitudeChoicesPane";
import { getTestRunner } from "../actions/AptitudeTest";

function AptitudeTestPage({}) {
	let test_end = useSelector((state) => state.mcq.test_state.test_end);
	let current_exam_pk = useSelector((state) => state.exam.current_exam_manager.exam_details.pk);
	const dispatch = useDispatch();
	const history = useHistory();

	// getting testrunner
	const [isPageLoading, UpdateisPageLoading] = useState(true);
	useEffect(() => {
		const Fetch = async (dispatch) => {
			const a = await dispatch(getTestRunner());
			UpdateisPageLoading(false);
		};

		Fetch(dispatch);
	}, []);

	if (isPageLoading) {
		return (
			<div
				style={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	} else {
		if (!test_end) {
			return (
				<div>
					<ContestHeader />
					<AptitudeTestBar />

					<SplitPane
						split="vertical"
						defaultSize="50vw"
						primary="second"
						style={{ display: "flex", height: "calc(100% - 28.5mm)" }}
						allowResize={false}
					>
						<AptitudeQuestionPane />
						<AptitudeChoicesPane />
					</SplitPane>
				</div>
			);
		} else {
			// redirect to sections page of exam
			if (current_exam_pk > 0) {
				let exam_link = "/exam/" + String(current_exam_pk);
				return <Redirect to={exam_link} />;
			}

			// default end page when aptitude test is not part of an exam as a section
			else {
				return (
					<div>
						<NavBar />
						<br />
						<br />

						<Container>
							<Alert style={{ textAlign: "center" }} variant="info">
								Test completed.
							</Alert>
						</Container>
					</div>
				);
			}
		}
	}
}

export default connect(null, {})(AptitudeTestPage);

// Todo:
// Display longer list of questions more gracefully
