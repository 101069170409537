import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { forgot_password_send_email } from "../../actions/auth";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useStyles } from "./styles";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://material-ui.com/">
				JLR CodeRace
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

function ForgotPasswordGrid({ setSideGrid, forgot_password_send_email }) {
	const classes = useStyles();

	const initialFormData = Object.freeze({
		email: "",
	});
	const [formData, setFormData] = useState(initialFormData);

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
	};

	const [ButtonPressed, UpdateButtonPressed] = useState(false);
	const JSXcircularspinner = (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<CircularProgress size="1.5rem" />
		</div>
	);
	const JSXButton = ButtonPressed ? JSXcircularspinner : "Send Email";

	const handleSubmit = async (e) => {
		e.preventDefault();
		UpdateButtonPressed(true);
		await forgot_password_send_email(formData.email);
		UpdateButtonPressed(false);
	};

	return (
		<Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Forgot Password
				</Typography>
				<form className={classes.form} noValidate>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address of your account"
						name="email"
						autoComplete="email"
						autoFocus
						onChange={handleChange}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={handleSubmit}
						disabled={ButtonPressed}
					>
						{JSXButton}
					</Button>
					<Grid container>
						<Grid item>
							<Link
								href="#"
								variant="body2"
								onClick={(e) => {
									e.preventDefault();
									setSideGrid(1);
								}}
							>
								{"Don't have an account? Sign Up"}
							</Link>
						</Grid>
					</Grid>
					<Box mt={5}>
						<Copyright />
					</Box>
				</form>
			</div>
		</Grid>
	);
}

export default connect(null, { forgot_password_send_email })(ForgotPasswordGrid);
