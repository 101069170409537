export const AUTH = {
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAIL: "LOGIN_FAIL",
	USER_LOADED_SUCCESS: "USER_LOADED_SUCCESS",
	USER_LOADED_FAIL: "USER_LOADED_FAIL",
	SET_PASSWORD_GET_SUCCESS: "SET_PASSWORD_GET_SUCCESS",
	SET_PASSWORD_GET_FAIL: "SET_PASSWORD_GET_FAIL",
	NUKE_STORE: "NUKE_STORE",
};

export const TEST_MANAGER = {
	GET_SUCCESS: "Mcq/GET_SUCCESSFUL",
	GET_FAILED: "Mcq/GET_FAILED",
	GET_MCQ_DETAILS_SUCCESS: "Mcq/GET_MCQ_DETAILS_SUCCESS",
	GET_MCQ_DETAILS_FAIL: "Mcq/GET_MCQ_DETAILS_FAIL",
	END_MCQ_TEST: "Mcq/END_MCQ_TEST",
};

export const USER_PROFILE = {
	GET_PROFILE_SUCCESS: "UserProfile/GET_PROFILE_SUCCESS",
	POST_PROFILE_SUCCESS: "UserProfile/POST_PROFILE_SUCCESS",
	GET_RECRUITMENT_PROFILE_SUCCESS: "UserProfile/GET_RECRUITMENT_PROFILE_SUCCESS",
	POST_RECRUITMENT_PROFILE_SUCCESS: "UserRECRUITMENT_Profile/POST_RECRUITMENT_PROFILE_SUCCESS",
};

export const EXAM_MANAGER = {
	GET_LIVE_EXAMS_SUCCESS: "ExamManager/GET_LIVE_EXAMS_SUCCESS",
	GET_LIVE_EXAMS_FAIL: "ExamManager/GET_LIVE_EXAMS_FAIL",
	GET_EXAM_SECTIONS_STATE_SUCCESS: "ExamManager/GET_EXAM_SECTIONS_STATE_SUCCESS",
	GET_EXAM_SECTIONS_STATE_FAIL: "ExamManager/GET_EXAM_SECTIONS_STATE_FAIL",
	SET_CURRENT_EXAM_STATE: "ExamManager/SET_CURRENT_EXAM_STATE",
	GET_CURRENT_EXAM_RUNNER_SUCCESS: "ExamManager/GET_CURRENT_EXAM_RUNNER_SUCCESS",
	UPDATE_CURRENT_EXAM_RUNNER_SUCCESS: "ExamManager/UPDATE_CURRENT_EXAM_RUNNER_SUCCESS",
	REMOVE_CURRENT_EXAM_INFORMATION: "ExamManager/REMOVE_CURRENT_EXAM_INFORMATION",
	GET_EXAM_LIST_REQUEST:'ExamManager/GET_EXAM_LIST_REQUEST',
	GET_EXAM_LIST_SUCCESS:'ExamManager/GET_EXAM_LIST_SUCCESS',
	GET_EXAM_LIST_FAIL:'ExamManager/GET_EXAM_LIST_FAIL',
};

export const ANNOUNCEMENT = {
	GET_ANNOUNCEMENT_REQUEST : 'GET_ANNOUNCEMENT_REQUEST',
 	GET_ANNOUNCEMENT_SUCCESS : 'GET_ANNOUNCEMENT_SUCCESS',
 	GET_ANNOUNCEMENT_FAIL : 'GET_ANNOUNCEMENT_FAIL',
};

